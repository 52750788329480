import orderMsg from "@/view/content/groupCenter/deail/orderMsg.vue";
import delivery from "@/view/content/groupCenter/deail/estimdelivery.vue";
import quoteSetup from "@/view/content/groupCenter/deail/quoteSetup.vue";
import cardMsg from "@/view/content/groupCenter/deail/cardMsg.vue";
import priceShow from "@/view/content/groupCenter/deail/priceShow.vue";
import linePriceShow from "@/view/content/groupCenter/deail/linePriceShow.vue";
import assess from "@/view/content/groupCenter/deail/assess.vue";
import axios from "@/api";
import * as echarts from 'echarts';
export default {
  components: {
    orderMsg, delivery, quoteSetup, cardMsg, priceShow, linePriceShow, assess
  },
  mounted() {
    this.getQueryVariable();
  },
  data() {
    return {
      query: {}, notification: {}, dictList: [], craft: {}, craftList: [], materialResult: {}, setUpPrice: {}, setUpList: [], excute: {totomlMoney: 0},
      estimateMethod: "", estimateRemark: "", productionCycleId: "", starvingNum: 0, urgentApproval: "", useAgeData: "", estimateDate: "", materialMsg: "",
      productArea: "", notiNeedDays: "", purchRowDays: 0, saleId: "", dayAreas: "", estimateStartDate: "", saleCsutomerId: "",
      saleCompanyId: "", oueDays: "", notiCraftNo: "", genCraftNo: "", groupCenterLine: {},
      canEditFlag: false, salePrecent:null, managePrecent:null, setUpArea:null, acceptFlag: "", showDays: 60,
      dayList: [], dayEntity: {}, addCapacityList: [],capacity: {},deailList: [],oldSetupList:[],maxBatchArea: 0,temp: 0, capacityList: [],
      materialList: [], materials: [], materialPrice: 0, materialArea: 0, useMatList: [], manufacturerList: [], manufacturer: "", orgList: [],
      maxBatchAreaConfirmList:[],materialListTwo:[],materialCat:{},appointMaterialFee:0,materialMsgTwo:null,negotiateAreaPrice:0,processCostPrice:0,
      bargainingRecord:{},pingEstimateMethod:"",materialUse:'',showOrgList:[],approveType:'',clickFlag: false,boardLength:null,boardWidth:null,boardThicknessId:null,
      copperCladThicknessId:null,materialNo:null,orderBatchArea:null,orderHours:15,tubePinFee:0
    };
  },
  methods: {
    canEdit: function() {
      // 反审功能，可编辑
      this.canEditFlag = true;
      if(this.manufacturer || this.manufacturer !== ""){
        this.resetMaterialPrice();
      }
    },
    getQueryVariable: function() {
      this.query = {};
      this.materialPrice = 0;
      this.materialArea = 0;
      this.starvingNum = 0;
      this.groupCenterLine = {};
      const param = window.location.href.split("?");
      if (param && param.length > 1) {
        const query = param[1];const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split("=");
          if (pair[0] === "n_id") {this.query.n_id = pair[1];}
          if (pair[0] === "u_id") {this.query.u_id = pair[1];}
          if (pair[0] === "g_id") {this.query.g_id = pair[1];}
        }
        if (this.query.n_id) {
          if (this.query.u_id) {
            const paramVal = {}; paramVal.recordId = this.query.n_id; paramVal.userId = this.query.u_id; paramVal.groupDbId = this.query.g_id;
            paramVal.length = this.boardLength; paramVal.width = this.boardWidth;paramVal.boardThicknessId = this.boardThicknessId;
            paramVal.copperCladThicknessId = this.copperCladThicknessId;paramVal.materialNo = this.materialNo;this.craft = {};const _this = this;
            axios.fetchPost("icloud/contract/getNotification", paramVal).then(result => {
                  if (result.data) {
                    if(result.data.result == 'fail') {
                      alert(result.data.message);
                      return;
                    }
                    if (result.data && result.data.dictList && result.data.dictList.length > 0) {
                      _this.maxBatchAreaConfirmList = result.data.confirmList || [];
                      _this.notification = result.data.notification;
                      _this.materialResult = result.data.materialResult;
                      _this.deailList = result.data.deailList;
                      _this.oldSetupList = result.data.oldSetupList;
                      // _this.maxBatchArea = 200;
                      _this.maxBatchArea = result.data.maxBatchArea ? result.data.maxBatchArea:0;
                      if(_this.materialResult && _this.materialResult.setLength && _this.materialResult.setWidth && _this.materialResult.pnlDivisor){
                        // 将工程尺寸覆盖订单尺寸、面积
                        _this.notification.conDeail.pnlLength = _this.materialResult.setLength;
                        _this.notification.conDeail.pnlWidth = _this.materialResult.setWidth;
                        _this.notification.conDeail.pnlDivisor = _this.materialResult.pnlDivisor;
                        _this.notification.conDeail.orderDeailArea = (Number(_this.notification.conDeail.pnlLength) * Number(_this.notification.conDeail.pnlWidth)* Number(_this.notification.conDeail.quantity)
                            / Number(_this.notification.conDeail.pnlDivisor) / 1000000).toFixed(4);
                      }
                      if (_this.notification && _this.notification.conDeail && _this.notification.pricees && _this.notification.pricees.price) {
                        _this.notification.pricees.areaPrice = ((Number(_this.notification.pricees.price) * Number(_this.notification.conDeail.quantity))
                            / Number(_this.notification.conDeail.orderDeailArea)).toFixed(3);
                        // 计算外币
                        if (_this.notification.exchangeType && _this.notification.exchangeRate){
                          if (_this.notification.pricees){
                            _this.notification.pricees.exchangeType = _this.notification.exchangeType;
                            _this.notification.pricees.exchangeRate = _this.notification.exchangeRate;
                            _this.notification.pricees.exchangeDate = _this.notification.exchangeDate;
                            _this.notification.pricees.exchangeDateStr = _this.notification.exchangeDateStr;
                            _this.notification.pricees.exPrice = 0;
                            _this.notification.pricees.exAreaPrice = 0;
                            _this.notification.conDeail.exSubTotal = 0;
                            if (_this.notification.pricees.price){
                              _this.notification.pricees.exPrice = _this.notification.pricees.price;
                              _this.notification.pricees.price = (Number(_this.notification.pricees.price) * Number(_this.notification.exchangeRate)).toFixed(6);
                            }
                            if (_this.notification.pricees.areaPrice){
                              _this.notification.pricees.exAreaPrice = _this.notification.pricees.areaPrice;
                              _this.notification.pricees.areaPrice = (Number(_this.notification.pricees.areaPrice) * Number(_this.notification.exchangeRate)).toFixed(4);
                            }
                            if (_this.notification.conDeail.subTotal){
                              _this.notification.conDeail.exSubTotal = _this.notification.conDeail.subTotal;
                              _this.notification.conDeail.subTotal = (Number(_this.notification.conDeail.subTotal) * Number(_this.notification.exchangeRate)).toFixed(2);
                              _this.notification.conDeail.computAmount = (Number(_this.notification.conDeail.computAmount) * Number(_this.notification.exchangeRate)).toFixed(2);
                            }
                          }
                        }
                      }
                      _this.notification.conDeail.proRemark = _this.notification.conDeail.remark;
                      _this.craftList = result.data.craftList;
                      _this.materialList = result.data.materialList;
                      _this.materialListTwo = result.data.materialListTwo;

                      // 材料平米单价
                      if (_this.materialList && _this.materialList.length > 0){
                        _this.materialPrice = _this.materialList[0].avgPrice;
                        this.useMaterialId = _this.materialList[0].recordId;
                        this.materialRateRule = _this.materialList[0].priceCpmputRsult;
                      }
                      // 可用料总面积
                      _this.materials = result.data.materials || [];
                      for (let i=0;i<_this.materials.length;i++){
                        if (_this.materials[i].customerId != _this.notification.customerId)
                        {
                          continue;
                        }
                        let canUseArea = _this.materials[i].canUseArea?Number(_this.materials[i].canUseArea):0;
                        _this.materialArea = Number(_this.materialArea) + Number(canUseArea);
                      }
                      _this.manufacturerList = result.data.manufacturerList;
                      _this.orgList = result.data.orgList;

                      _this.showOrgList = [];
                      if(_this.materialList && _this.materialList.length > 0)
                      {
                        for(let mat of _this.materialList)
                        {
                          _this.showOrgList.push(mat);
                        }
                      }
                      if(_this.orgList && _this.orgList.length > 0)
                      {
                        for(let mat of _this.orgList)
                        {
                          let flag = false;
                          if(_this.showOrgList && _this.showOrgList.length > 0)
                          {
                            for(let showOrg of _this.showOrgList)
                            {
                              if(showOrg.recordId == mat.recordId)
                              {
                                flag = true;
                                break;
                              }
                            }
                          }
                          if(!flag)
                          {
                            _this.showOrgList.push(mat);
                          }
                        }
                      }
                      _this.dictList = result.data.dictList;
                      _this.dealCraftUtil();
                      _this.salePrecent = result.data.salePrecent; // 业务费比例
                      _this.managePrecent = result.data.managePrecent; // 管理费比例
                      _this.setUpArea = result.data.setUpArea; // 样品平米设置数

                      _this.setUpPrice.setUpAreaPrice = result.data.setUpAreaPrice;
                      _this.setUpPrice.setUpPcsPrice = result.data.setUpPcsPrice;
                      _this.setUpPrice.setUpHolePrice = result.data.setUpHolePrice;
                      _this.setUpPrice.setUpFixedFee = result.data.setUpFixedFee;
                      _this.setUpList = result.data.setUpList;
                      if (_this.setUpList && _this.setUpList.length > 0){
                        let orderDeailArea = _this.notification.conDeail.orderDeailArea?Number(_this.notification.conDeail.orderDeailArea):0;
                        let quantity = _this.notification.conDeail.quantity?Number(_this.notification.conDeail.quantity):0;
                        let countersinkHole = _this.notification.conDeail.countersinkHole?Number(_this.notification.conDeail.countersinkHole):0;
                        for (let i=0;i<_this.setUpList.length;i++){
                          if (_this.setUpList[i] && _this.setUpList[i].list && _this.setUpList[i].list.length > 0){
                            for (let j=0;j<_this.setUpList[i].list.length;j++){
                              let countPrice = _this.setUpList[i].list[j].price?Number(_this.setUpList[i].list[j].price):0;
                              if (_this.setUpList[i].countMethod == 1){
                                _this.setUpList[i].list[j].countMoney = _this.formatMoney((countPrice * orderDeailArea).toFixed("2"));
                              }else if (_this.setUpList[i].countMethod == 2){
                                _this.setUpList[i].list[j].countMoney = _this.formatMoney((countPrice * quantity).toFixed("2"));
                              }else if (_this.setUpList[i].countMethod == 3){
                                _this.setUpList[i].list[j].countMoney = _this.formatMoney((countPrice * countersinkHole).toFixed("2"));
                              }else {
                                _this.setUpList[i].list[j].countMoney = _this.formatMoney(countPrice.toFixed("2"));
                              }
                            }
                          }
                        }
                      }
                      if (result.data && result.data.groupCenter && result.data.groupCenter.recordId) {
                        _this.estimateDate = result.data.groupCenter.estimateDateStr;
                        _this.groupCenterLine = result.data.groupCenter;
                        _this.manufacturer = result.data.groupCenter.manufacturer;
                        if(_this.groupCenterLine)
                        {
                          if (_this.groupCenterLine.urgentFlag) {
                            _this.urgentApproval = _this.groupCenterLine.urgentFlag;
                          }
                          if (_this.groupCenterLine.useAgeData) {
                            _this.useAgeData = _this.groupCenterLine.useAgeData;
                          }
                          _this.appointMaterialFee = _this.groupCenterLine.appointMaterialFee;
                          _this.negotiateAreaPrice = _this.groupCenterLine.negotiateAreaPrice;
                          _this.processCostPrice = _this.groupCenterLine.processCostPrice;

                          _this.boardLength = _this.groupCenterLine.length;
                          _this.boardWidth = _this.groupCenterLine.width;
                          _this.boardThicknessId = _this.groupCenterLine.boardThicknessId;
                          _this.copperCladThicknessId = _this.groupCenterLine.copperCladThicknessId;
                          _this.materialNo = _this.groupCenterLine.materialNo;
                        }
                      }
                      _this.materialResult.useRatio = result.data.useRatio;
                    }
                    _this.isGroup();
                    _this.coutExcutePrice();
                    _this.countMaterialMoney();
                  }
                }).catch(err => {console.log(err);});
          } else {
            alert("找不到用户");
            window.opener = null;
            window.open("", "_self");
            window.close();
          }
        } else {
          alert("找不到通知单");
          window.opener = null;
          window.open("", "_self");
          window.close();
        }
      }
    },
    resetMaterialPrice: function () {
      const _this = this;
      const material = {};
      material.manufacturer = this.manufacturer;
      material.boardLevelId = this.notification.craft.boardLevel;
      material.materialTypeId = this.notification.craft.materialType;
      material.boardThicknessId = this.notification.craft.boardThickness;
      material.daoreId = this.notification.craft.daore;
      material.naiyaId = this.notification.craft.naiya;
      material.copperCladThicknessId = this.notification.craft.copperCladThickness;

      material.bordLength = this.boardLength;
      material.bordWidth = this.boardWidth;
      material.boardThicknessIdTwo = this.boardThicknessId;
      if(this.copperCladThicknessId)
      {
        material.copperCladThicknessId = this.copperCladThicknessId;
      }
      material.materialNo = this.materialNo;
      if(this.materialCat && this.materialCat.recordId)
      {
        material.recordId = this.materialCat.recordId;
      }
      axios.fetchPost("icloud/contract/resetMaterialPrice", material).then(result => {
        _this.orgList = result.data.orgList;
        _this.materialList = result.data.materialList;
        _this.showOrgList = [];
        if(_this.materialList && _this.materialList.length > 0)
        {
          for(let mat of _this.materialList)
          {
            _this.showOrgList.push(mat);
          }
        }
        if(_this.orgList && _this.orgList.length > 0)
        {
          for(let mat of _this.orgList)
          {
            let flag = false;
            if(_this.showOrgList && _this.showOrgList.length > 0)
            {
              for(let showOrg of _this.showOrgList)
              {
                if(showOrg.recordId == mat.recordId)
                {
                  flag = true;
                  break;
                }
              }
            }
            if(!flag)
            {
              _this.showOrgList.push(mat);
            }
          }
        }
        // 材料平米单价
        if (_this.materialList.length > 0){
          _this.materialPrice = _this.materialList[0].avgPrice;
        }
        _this.countMaterialMoney();
      }).catch(err => {console.log(err);});
    },
    isGroup: function() {
      this.canEditFlag = false;
      if (!(this.groupCenterLine && this.groupCenterLine.recordId)){
        if ((this.notification.conDeail.quantity && this.notification.conDeail.quantity > 0) || !this.notification.conDeail.dictOrderType){
          this.canEditFlag = true;
        }
      }
    },
    setMsg: function(val) {
      if (val) {
        this.addCapacityList = val.addCapacityList;
        this.estimateMethod = val.estimateMethod;
        this.estimateRemark = val.estimateRemark;
        this.productionCycleId = val.productionCycleId;
        this.urgentApproval = val.urgentApproval;
        this.useAgeData = val.useAgeData;
        this.notiCraftNo = val.notiCraftNo;
        this.genCraftNo = val.genCraftNo;
        this.estimateDate = val.estimateDate;
        this.notiNeedDays = val.notiNeedDays;
        this.purchRowDays = val.purchRowDays;
        this.maxBatchAreaConfirm = val.maxBatchAreaConfirm;
        this.materialListTwo = val.materialListTwo;
        this.materialCat = val.materialCat;
        this.materialMsgTwo = val.materialMsgTwo;
        this.appointMaterialFee = val.appointMaterialFee;
        this.negotiateAreaPrice = val.negotiateAreaPrice;
        this.processCostPrice = val.processCostPrice;
        this.pingEstimateMethod = val.pingEstimateMethod;
        this.materialUse = val.materialUse;
        this.approveType = val.approveType;

        this.boardLength = val.boardLength;
        this.boardWidth = val.boardWidth;
        this.boardThicknessId = val.boardThicknessId;
        this.copperCladThicknessId = val.copperCladThicknessId;
        this.materialNo = val.materialNo;
      }
    },
    setComputRule:function (obj){
      let processFeeRule = null;
      if(this.setUpList && this.setUpList.length > 0)
      {
        for(let setUp of this.setUpList)
        {
          if(!(setUp.list && setUp.list.length > 0))
          {
            continue;
          }
          for(let item of setUp.list)
          {
            if(!item.useFlag)
            {
              continue;
            }
            processFeeRule = processFeeRule ? processFeeRule +'<br/>'+ setUp.name +' : ' : setUp.name +' : ';
            if(item.list && item.list.length > 0)
            {
              for(let itemRow of item.list)
              {
                if(itemRow.type == 1)
                {
                  if(itemRow.columnConditionTwo == '4')
                  {
                    processFeeRule = processFeeRule +' '+ itemRow.columnValTwo +' ＜ ';
                  }
                  else if(itemRow.columnConditionTwo == '6')
                  {
                    processFeeRule = processFeeRule +' '+ itemRow.columnValTwo +' ≤ ';
                  }
                  switch (itemRow.columnCondition)
                  {
                    case '1':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' = ' + itemRow.columnValVal;
                      break;
                    case '2':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' ≠ ' + itemRow.columnValVal;
                      break;
                    case '3':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' ＜ ' + itemRow.columnValVal;
                      break;
                    case '4':
                      processFeeRule = processFeeRule +' '+ itemRow.columnValVal +' ＜ ' + itemRow.itemName;
                      break;
                    case '5':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' ≤ ' + itemRow.columnValVal;
                      break;
                    case '6':
                      processFeeRule = processFeeRule +' '+ itemRow.columnValVal +' ≤ ' + itemRow.itemName;
                      break;
                  }
                  if(itemRow.columnConditionTwo == '3')
                  {
                    processFeeRule = processFeeRule +' ＜ '+ itemRow.columnValTwo;
                  }
                  else if(itemRow.columnConditionTwo == '5')
                  {
                    processFeeRule = processFeeRule +' ≤ ' + itemRow.columnValTwo;
                  }
                }
                else if(itemRow.type == 2)
                {
                  switch (itemRow.columnCondition)
                  {
                    case '1':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' = ';
                      break;
                    case '2':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' ≠ ';
                      break;
                    case '3':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' 包含 ' + itemRow.columnValVal;
                      break;
                    case '4':
                      processFeeRule = processFeeRule +' '+ itemRow.itemName +' 不包含 ' + itemRow.columnValVal;
                      break;
                  }
                }
              }
            }
            processFeeRule = processFeeRule +' 单价:￥ ' + item.price;
            switch (setUp.countMethod)
            {
              case 1:
                processFeeRule = processFeeRule +' 元/㎡ ';
                break;
              case 2:
                processFeeRule = processFeeRule +' 元/PCS ';
                break;
              case 3:
                processFeeRule = processFeeRule +' 元/孔 ';
                break;
              case 4:
                processFeeRule = processFeeRule +' 元 ';
                break;
            }
            processFeeRule = processFeeRule +' 金额:￥ ' + item.countMoney + ' 元 ';
          }

        }
      }
      let materialFeeRule = '材料平米单价 * (订单平米 / 利用率)('+this.materialMsg +')';
      obj.processFeeRule = processFeeRule;
      obj.materialFeeRule = materialFeeRule;
    },
    saveGroupCenter: function() {
/*      if (this.clickFlag)
      {
        alert("请勿重复点击!");
        return;
      }*/
      if(this.productionCycleId != "1" && this.productionCycleId != "2") {
        alert("评估结果有问题，无法保存");
        return;
      }
      // 判断交期评估是否能够达到
      if (this.productionCycleId == "2" && this.starvingNum > 0 && !(this.useAgeData && this.useAgeData == 4)) {
        alert("该材料缺少" + this.starvingNum + "平米，请补充材料，如果选择主料的数量不满足，材料可以是申购或者是余料或者替换料");
        return;
      }
      if ((!this.notiCraftNo || this.notiCraftNo === "") && (!this.genCraftNo || this.genCraftNo === "")){
        alert("厂编不能为空");
        return;
      }
/*      if(this.notification.conDeail && this.notification.conDeail.dictOrderType == 2 && !this.notification.conDeail.oldDeailId && !(this.useAgeData && this.useAgeData == 3) && !(this.urgentApproval == '1')){
        if(!this.manufacturer || this.manufacturer === ""){
          alert("请选择报备材料品牌");
          return;
        }
      }*/
      if(this.notification.conDeail.dictOrderType == 2 && !this.notification.conDeail.oldDeailId && !this.notification.conDeail.oldDeailId && this.urgentApproval == '1'
          && ((this.useAgeData && this.useAgeData != 1 && this.useAgeData != 3) || !this.useAgeData || this.useAgeData == ''))
      {
        if(!this.materialUse)
        {
          alert("请选择加急用料方式");
          return;
        }
      }
      if(this.notification.conDeail.dictOrderType == 2 && !this.notification.conDeail.oldDeailId && !this.notification.conDeail.oldDeailId && this.urgentApproval == '1' && this.materialUse == '2')
      {
        if(!this.materialCat || !this.materialCat.recordId)
        {
          alert("请选择具体料");
          return;
        }
      }
      const groupCenter = {};
      groupCenter.deptId = this.notification.deptId;
      if(this.materialCat && this.materialCat.recordId)
      {
        groupCenter.materialId = this.materialCat.recordId;
      }
      groupCenter.userId = this.query.u_id;
      // 市场备料占用
      if (this.useMatList && this.useMatList.length > 0){
        groupCenter.areas = this.useMatList;
      }
      // 成本费用管理
      groupCenter.processFee = this.excute.totomlMoney;
      groupCenter.materialFee = this.notification.materialFee;
      groupCenter.costPrice = this.notification.productPriceOne;
      groupCenter.lnPrice = this.notification.economyFeePrice;
      groupCenter.jxPrice = this.notification.factoryFeePrice;
      groupCenter.estimateRemark = this.estimateRemark;
      groupCenter.productionCycleId = this.productionCycleId;
      groupCenter.appointMaterialFee = this.appointMaterialFee;
      groupCenter.negotiateAreaPrice = this.negotiateAreaPrice;
      groupCenter.processCostPrice = this.processCostPrice;
      // 基础资料
      groupCenter.useRatio = this.materialResult.useRatio;
      groupCenter.saleCsutomerId = this.materialResult.salecustId;
      groupCenter.saleCompanyId = this.materialResult.saleCompanyId;
      // 交期资料
      groupCenter.estimateMethod = this.estimateMethod;
      groupCenter.notiNeedDays = this.notiNeedDays?Number(this.notiNeedDays):0;
      groupCenter.purchRowDays = this.purchRowDays?Number(this.purchRowDays):0;
      groupCenter.estimateDate = this.estimateDate;
      groupCenter.contactDeailId = this.notification.conDeail.recordId;
      // 样品费用置空
      if(this.notification.conDeail && this.notification.conDeail.dictOrderType == 1)
      {
        // groupCenter.processFee = null;
        groupCenter.materialFee = null;
        groupCenter.costPrice = null;
        groupCenter.lnPrice = null;
        groupCenter.jxPrice = null
        // 样品设置采购工程费为加工费
        groupCenter.prdEngineeringFee = groupCenter.processFee;
        groupCenter.appointMaterialFee = null;
        groupCenter.negotiateAreaPrice = null;
        groupCenter.processCostPrice = null;
      }
      // 厂编资料
      groupCenter.notiCraftNo = this.notiCraftNo;
      groupCenter.genCraftNo = this.genCraftNo;

      if (this.groupCenterLine && this.groupCenterLine.recordId) {
        groupCenter.recordId = this.groupCenterLine.recordId;
      }
      groupCenter.addCapacityList = this.addCapacityList;
      // 生产周期版本,每次更新的时候更新
      groupCenter.versionDate = this.materialResult.versionDate;
      groupCenter.versionCapacityDate = this.materialResult.capacityVersionDate;
      // 加急通道
      groupCenter.urgentFlag = this.urgentApproval;
      groupCenter.useAgeData = this.useAgeData;
      groupCenter.manufacturer = this.manufacturer;
      groupCenter.materialMsg = this.materialMsg;
      // 加急用料方式
      groupCenter.materialUse = this.materialUse;

      groupCenter.saleFee = this.notification.saleFee;
      groupCenter.manageFee = this.notification.manageFee;
      if(isFinite(this.notification.grossProfitRate)){
        groupCenter.grossProfitRate = this.notification.grossProfitRate;
      }
      if(isFinite(this.notification.netProfitRate)){
        groupCenter.netProfitRate = this.notification.netProfitRate;
      }
      groupCenter.areaPrice = this.materialPrice;

      let confirmId = null;
      if(this.maxBatchAreaConfirm && this.maxBatchAreaConfirm.recordId)
      {
        confirmId = this.maxBatchAreaConfirm.recordId;
      }
      if(this.maxBatchAreaConfirmList && this.maxBatchAreaConfirmList.length > 0)
      {
        for(let confirm of this.maxBatchAreaConfirmList)
        {
          if(confirmId)
          {
            confirmId = confirmId +","+ confirm.recordId;
          }
          else
          {
            confirmId = confirm.recordId;
          }
        }
      }
      groupCenter.confirmId = confirmId;
      // 采购费用
      groupCenter.prdAmount = this.notification.prdAmount;
      // 原材料费
      groupCenter.rawMaterialCost = this.bargainingRecord.rawMaterialCost;
      // 原加工费
      groupCenter.originalProcessingFee = this.bargainingRecord.originalProcessingFee;
      // 加工平米单价
      groupCenter.squareMetrePrice = this.setUpPrice.setUpAreaPrice;
      // 加急审批走不走流程
      groupCenter.approveType = this.approveType;

      groupCenter.length = this.boardLength;
      groupCenter.width = this.boardWidth;
      groupCenter.boardThicknessId = this.boardThicknessId;
      groupCenter.copperCladThicknessId = this.copperCladThicknessId;
      groupCenter.materialNo = this.materialNo;
      groupCenter.maxBatchArea = this.maxBatchArea;
      // 赋值材料费和加工费计算规则
      this.setComputRule(groupCenter);
      if (this.materialList && this.materialList.length > 0){
        groupCenter.useMaterialId = this.materialList[0].recordId;
        groupCenter.materialRateRule = this.materialList[0].priceCpmputRsult;
      }
      this.clickFlag = true;
      const _this = this;
      axios.fetchPost("icloud/purchasing/saveGroupCenter", groupCenter).then(result => {
          if (result.data && result.data.result) {
            _this.clickFlag = false;
            alert(result.data.message);
            if (result.data.result === "success") {
              _this.canEditFlag = false;
              window.opener = null;
              window.open("", "_self");
              window.close();
            }
          } else {
            alert("保存失败，产能款数有变，请刷新重试");
          }
        }).catch(err => {console.log(err);});
    },
    acceptDeliverDate: function() {
      this.acceptFlag = null;
      this.acceptFlag = 1;
    },
    dealCraftUtil: function() {
      const data = {};
      data.data = this.dictList;
      const _this = this;
      _this.craft.currencyTypeList = [{recordId:'',value:""}];
      _this.craft.payWayList = [{recordId:'',value:""}];
      _this.craft.taxDescriptList = [{recordId:'',value:""}];
      _this.craft.deliverywaysList = [{recordId:'',value:""}];
      _this.craft.freightwaysList = [{recordId:'',value:""}];
      _this.craft.deliverydaysList = [{recordId:'',value:""}];
      _this.craft.boardLevelList = [{recordId:'',value:""}];
      _this.craft.materialTypeList = [{recordId:'',value:""}];
      _this.craft.boardThicknessList = [{recordId:'',value:""}];
      _this.craft.copperCladThicknessList = [{recordId:'',value:""}];
      _this.craft.surfaceProcessList = [{recordId:'',value:""}];
      _this.craft.solderMaskTypeList = [{recordId:'',value:""}];
      _this.craft.characterTypeList = [{recordId:'',value:""}];
      _this.craft.shapingWayList = [{recordId:'',value:""}];
      _this.craft.testMethodList = [{recordId:'',value:""}];
      _this.craft.ordertypeList = [{recordId:'',value:""}];
      _this.craft.productionTypeList = [{recordId:'',value:""}];
      _this.craft.lingeSpacingList = [{recordId:'',value:""}];
      _this.craft.smallApertureList = [{recordId:'',value:""}];
      _this.craft.halAholeList = [{recordId:'',value:""}];
      _this.craft.buryBlindHoleList = [{recordId:'',value:""}];
      _this.craft.resistanceList = [{recordId:'',value:""}];
      _this.craft.deliveryUrgentList = [{recordId:'',value:""}];
      _this.craft.engineeringfeeList = [{recordId:'',value:""}];
      _this.craft.daoreList = [{recordId:'',value:""}];
      _this.craft.naiyaList = [{recordId:'',value:""}];
      _this.craft.pliesnumberList = [{recordId:'',value:""}];
      _this.craft.inkTypeList = [{recordId:'',value:""}];
      _this.craft.specialTypeList = [{recordId:'',value:""}];
      if (data.data && data.data.length > 0) {
        for (let i = 0; i < data.data.length; i++) {
          const craft = {};
          craft.recordId = data.data[i].valId;
          craft.value = data.data[i].value;
          if (data.data[i].recordId === "11") {
            _this.craft.currencyTypeList.push(craft);
          } else if (data.data[i].recordId === "13") {
            _this.craft.payWayList.push(craft);;
          } else if (data.data[i].recordId === "19") {
            _this.craft.taxDescriptList.push(craft);;
          } else if (data.data[i].recordId === "22") {
            _this.craft.deliverywaysList.push(craft);;
          } else if (data.data[i].recordId === "23") {
            _this.craft.freightwaysList.push(craft);;
          } else if (data.data[i].recordId === "24") {
            _this.craft.deliverydaysList.push(craft);;
          } else if (data.data[i].recordId === "1") {
            _this.craft.boardLevelList.push(craft);;
          } else if (data.data[i].recordId === "2") {
            _this.craft.materialTypeList.push(craft);;
          } else if (data.data[i].recordId === "3") {
            _this.craft.boardThicknessList.push(craft);;
          } else if (data.data[i].recordId === "31") {
            _this.craft.copperCladThicknessList.push(craft);;
          } else if (data.data[i].recordId === "5") {
            _this.craft.surfaceProcessList.push(craft);;
          } else if (data.data[i].recordId === "6") {
            _this.craft.solderMaskTypeList.push(craft);;
          } else if (data.data[i].recordId === "7") {
            _this.craft.characterTypeList.push(craft);;
          } else if (data.data[i].recordId === "8") {
            _this.craft.shapingWayList.push(craft);;
          } else if (data.data[i].recordId === "9") {
            _this.craft.testMethodList.push(craft);;
          } else if (data.data[i].recordId === "16") {
            _this.craft.ordertypeList.push(craft);;
          } else if (data.data[i].recordId === "17") {
            _this.craft.productionTypeList.push(craft);;
          } else if (data.data[i].recordId === "37") {
            _this.craft.lingeSpacingList.push(craft);;
          } else if (data.data[i].recordId === "38") {
            _this.craft.smallApertureList.push(craft);;
          } else if (data.data[i].recordId === "39") {
            _this.craft.halAholeList.push(craft);;
          } else if (data.data[i].recordId === "40") {
            _this.craft.buryBlindHoleList.push(craft);;
          } else if (data.data[i].recordId === "41") {
            _this.craft.resistanceList.push(craft);;
          } else if (data.data[i].recordId === "44") {
            _this.craft.deliveryUrgentList.push(craft);;
          } else if (data.data[i].recordId === "49") {
            _this.craft.engineeringfeeList.push(craft);;
          } else if (data.data[i].recordId === "58") {
            _this.craft.daoreList.push(craft);;
          } else if (data.data[i].recordId === "59") {
            _this.craft.naiyaList.push(craft);;
          } else if (data.data[i].recordId === "60") {
            _this.craft.pliesnumberList.push(craft);;
          } else if (data.data[i].recordId === "62") {
            _this.craft.inkTypeList.push(craft);;
          } else if (data.data[i].recordId === "67") {
            _this.craft.specialTypeList.push(craft);;
          }
        }
      }
    },
    formatMoney: function(s) {
      if (!isNaN(s)) {
        var noNegative = true; // 默认是正值。
        s = parseFloat(s + "").toFixed(2);
        s = s + ""; // 转换成字符串
        if (parseFloat(s) < 0) {
          // 是负数
          s = Math.abs(s).toFixed(2) + "";
          noNegative = false;
        }
        var zheng = s.split(".")[0];
        var dian = s.split(".")[1];
        // 将整数部分，利用字符串的charAt() 方法，转换成数组。
        var zhengArr = [];
        for (var i = 0; i < zheng.length; i++) {
          zhengArr.push(zheng.charAt(i));
        }
        zhengArr = zhengArr.reverse();
        var t = "";
        for (let i = 0; i < zhengArr.length; i++) {
          if (i % 3 === 2 && i !== zhengArr.length - 1) {
            // 为第三位，并且并不是最后了。如123456时，6并不加,
            t += zhengArr[i] + ",";
          } else {
            t += zhengArr[i] + ""; // 加上空格
          }
        }
        return ((noNegative ? "" : "-") + t.split("").reverse().join("") + "." + dian);
      }
      return 0;
    },
    handleData:function(val)
    {
      if(val.materialCat && val.materialCat.recordId)
      {
        this.materialCat = val.materialCat;
        this.resetMaterialPrice();
      }
      this.countMaterialMoney(val);
    },
    countMaterialMoney: function (val)
    {
      if (val) {
        this.urgentApproval = val.urgentApproval;
        this.materialUse = val.materialUse;
      }
      if((this.urgentApproval && this.urgentApproval == '1') || (this.useAgeData && this.useAgeData == 3))
      {
        this.manufacturer = "";
      }
      if(this.negotiateAreaPrice && this.negotiateAreaPrice > 0)
      {
        if (!this.bargainingRecord.rawMaterialCost || this.bargainingRecord.rawMaterialCost == null)
        {
          this.bargainingRecord.rawMaterialCost = this.notification.materialFee
        }
        this.materialPrice = this.negotiateAreaPrice;
      }
      if(!this.materialPrice && this.materialList && this.materialList.length > 0)
      {
        this.materialPrice = this.materialList[0].avgPrice;
      }

      this.materialMsg = "";
      this.notification.materialFee = 0;
      this.starvingNum = 0;

      if (this.processCostPrice && this.processCostPrice > 0) {
        if (!this.bargainingRecord.originalProcessingFee || this.bargainingRecord.originalProcessingFee == null)
        {
          this.bargainingRecord.originalProcessingFee = this.excute.totomlMoney
        }
        let area = Number(this.notification.conDeail.orderDeailArea);
        let price = Number(this.processCostPrice);
        let totomlMoney = area * price;
        totomlMoney = totomlMoney.toFixed(4);
        this.excute.totomlMoney = totomlMoney;
      }

      let materialFee = 0;
      // 量产计算材料费和使用备料
      if (this.notification.conDeail.dictOrderType == 2){
        // 计算订单需要面积
        let orderDeailArea = this.notification.conDeail.orderDeailArea?Number(this.notification.conDeail.orderDeailArea):0;
        orderDeailArea = (orderDeailArea / this.materialResult.useRatio * 100).toFixed(4);
        // 使用备料面积
        this.useMatList = [];
        this.starvingNum = orderDeailArea;
        if (!(this.useAgeData && this.useAgeData == 3) && !(this.urgentApproval == '1') && this.materialPrice <= 0){
          alert("可计算材料单价要大于0，请确定有没有材料？没有让采购进行录入！");
          return;
        }
        if(this.useAgeData && this.useAgeData != '')
        {
          if(this.useAgeData == 1)
          {
            let useArea = 0;
            if(orderDeailArea > 0 && this.materials && this.materials.length > 0) {
              for (let material of this.materials) {
                if (material.customerId && material.customerId != this.notification.customerId) {
                  continue;
                }
                if (!material.canUseArea || material.canUseArea == 0) {
                  continue;
                }
                let canUseArea = Number(material.canUseArea);
                let computArea = 0;
                if (orderDeailArea > canUseArea) {
                  orderDeailArea = Number((orderDeailArea - canUseArea).toFixed(4));
                  computArea = canUseArea;
                } else {
                  computArea = orderDeailArea;
                  orderDeailArea = 0;
                }
                if (computArea > 0) {
                  useArea = Number(useArea) + Number(computArea);
                  let useMat = {};
                  useMat.specification = material.specification;
                  useMat.useArea = computArea;
                  useMat.matPreparationId = material.matPreparationId;
                  useMat.deptId = this.materialResult.deptId
                  useMat.arrivalDate = material.arrivalDate;
                  this.useMatList.push(useMat)
                }
                if (orderDeailArea == 0) {
                  this.starvingNum = 0;
                  break;
                }
              }
            }
            this.starvingNum = 0;
            // 材料费计算
            if (this.materialPrice > 0){
              materialFee = (Number(this.materialPrice) * Number(orderDeailArea)).toFixed(4);
              this.materialMsg = "订料(" + this.materialPrice + "*" +orderDeailArea + ")";
            }
            if (useArea > 0 && this.materialPrice > 0){
              let fee = Number(this.materialPrice) * Number(useArea);
              materialFee = (Number(materialFee) + fee).toFixed(4);
              if (this.materialMsg) {
                this.materialMsg = this.materialMsg + " + 报备(" + this.materialPrice + "*" + useArea + ")";
              }else {
                this.materialMsg = "报备(" + this.materialPrice + "*" + useArea + ")";
              }
            }
          }
          else if(this.useAgeData == 3)
          {
            this.starvingNum = 0;
            materialFee = 0;
            this.materialPrice = 0;
            // 材料费计算
            this.materialMsg = "客供料(" +orderDeailArea + ")";
          }
        }
        if(this.urgentApproval && this.urgentApproval == 1 && ((this.materialUse && this.materialUse == 1) || (this.useAgeData && this.useAgeData != ''))) {
            // 具体料费用 + 加急费用
            if (this.materialPrice > 0 || (this.useAgeData && this.useAgeData != '')) {
                let materialFeeA = (Number(this.materialPrice) * Number(orderDeailArea)).toFixed(4);
                materialFee = (Number(materialFeeA) + 2 * Number(this.notification.conDeail.orderDeailArea)).toFixed(4);
                this.materialMsg = "材料费" + materialFeeA + "+加急费" + 2 + "*" + this.notification.conDeail.orderDeailArea;
                this.coutExcutePrice();
                this.excute.totomlMoney = (Number(this.excute.totomlMoney));
            }
            if(orderDeailArea > 0 && this.materials && this.materials.length > 0) {
                let useArea = 0;
                for (let material of this.materials) {
                    if (material.customerId && material.customerId != this.notification.customerId) {
                        continue;
                    }
                    if (!material.canUseArea || material.canUseArea == 0) {
                        continue;
                    }
                    let canUseArea = Number(material.canUseArea);
                    let computArea = 0;
                    if (orderDeailArea > canUseArea) {
                        orderDeailArea = orderDeailArea - canUseArea;
                        computArea = canUseArea;
                    } else {
                        computArea = orderDeailArea;
                        orderDeailArea = 0;
                    }
                    if (computArea > 0) {
                        useArea = Number(useArea) + Number(computArea);
                        let useMat = {};
                        useMat.specification = material.specification;
                        useMat.useArea = computArea;
                        useMat.matPreparationId = material.matPreparationId;
                        useMat.deptId = this.materialResult.deptId
                        useMat.arrivalDate = material.arrivalDate;
                        this.useMatList.push(useMat)
                    }
                    if (orderDeailArea == 0) {
                        this.starvingNum = 0;
                        break;
                    }
                }
            }
        }
        else if(this.urgentApproval && this.urgentApproval == 1) {
          this.starvingNum = 0;
          // 具体料费用 + 加急费用
          if (this.materialPrice > 0) {
            let materialFeeA = (Number(this.materialPrice) * Number(orderDeailArea)).toFixed(4);
            materialFee = (Number(materialFeeA) + 2 * Number(this.notification.conDeail.orderDeailArea)).toFixed(4);
            this.materialMsg = "材料费" + materialFeeA + "+加急费" + 2 + "*" + this.notification.conDeail.orderDeailArea;
            this.coutExcutePrice();
            this.excute.totomlMoney = (Number(this.excute.totomlMoney));
          }
        }
        if(!((this.useAgeData && this.useAgeData != '') || (this.urgentApproval && this.urgentApproval != '')))
        {
          if(orderDeailArea > 0 && this.materials && this.materials.length > 0)
          {
            let useArea = 0;
            for(let material of this.materials)
            {
              if (material.customerId && material.customerId != this.notification.customerId)
              {
                continue;
              }
              if(!material.canUseArea || material.canUseArea == 0)
              {
                continue;
              }
              let canUseArea = Number(material.canUseArea);
              let computArea = 0;
              if(orderDeailArea > canUseArea)
              {
                orderDeailArea = orderDeailArea - canUseArea;
                computArea = canUseArea;
              }
              else
              {
                computArea = orderDeailArea;
                orderDeailArea = 0;
              }
              if(computArea > 0)
              {
                useArea = Number(useArea) + Number(computArea);
                let useMat = {};
                useMat.specification = material.specification;
                useMat.useArea = computArea;
                useMat.matPreparationId = material.matPreparationId;
                useMat.deptId = this.materialResult.deptId
                useMat.arrivalDate = material.arrivalDate;
                this.useMatList.push(useMat)
              }
              if(orderDeailArea == 0)
              {
                this.starvingNum = 0;
                break;
              }
            }
            if (useArea > 0 && this.materialPrice > 0){
              let fee = Number(this.materialPrice) * Number(useArea);
              materialFee = (Number(materialFee) + fee).toFixed(4);
              if (this.materialMsg) {
                this.materialMsg = this.materialMsg + " + 报备(" + this.materialPrice + "*" + useArea + ")";
              }else {
                this.materialMsg = "报备(" + this.materialPrice + "*" + useArea + ")";
              }
            }
          }
        }
        if (!this.urgentApproval || this.urgentApproval == "" || this.urgentApproval == null)
        {
          this.coutExcutePrice();
        }
      }
      this.notification.materialFee = materialFee;
      if(this.starvingNum){
        this.starvingNum = Number(this.starvingNum).toFixed(4);
      }
      this.countOtherFee();
    },
    countOtherFee: function () {
      // 采购费
      this.notification.prdAmount = (Number(this.notification.prdMouldFee?this.notification.prdMouldFee:0) +
          Number(this.notification.prdTestShelfFee?this.notification.prdTestShelfFee:0)+
          Number(this.notification.prdEngineeringFee?this.notification.prdEngineeringFee:0)+
          Number(this.notification.prdFilmFee?this.notification.prdFilmFee:0)+
          Number(this.notification.prdOthersFee?this.notification.prdOthersFee:0)).toFixed("2");
      // 加工费+材料费
      this.notification.productFeeOne = (Number(this.excute.totomlMoney?this.excute.totomlMoney:0) + Number(this.notification.materialFee?this.notification.materialFee:0) + Number(this.notification.prdAmount?this.notification.prdAmount:0)).toFixed("2");
      this.notification.productFeeTwo = (Number(this.excute.totomlMoney?this.excute.totomlMoney:0) + Number(this.notification.materialFee?this.notification.materialFee:0)).toFixed("2");
      // 成本单价，不用业务费、管理费计算
      this.notification.productPriceOne = 0;
      if(this.notification.productFeeOne > 0 && this.notification.conDeail.quantity > 0) {
        this.notification.productPriceOne = (this.notification.productFeeTwo / Number(this.notification.conDeail.quantity? this.notification.conDeail.quantity:0)).toFixed("6");
      }
      // 样品没有业务费、管理费
      if (!this.notification.dictOrderType || this.notification.dictOrderType == 2)
      {
        // 业务费
        this.notification.saleFee = Number(((this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 0) *
            Number(this.salePrecent?this.salePrecent:0)) / 100).toFixed("3");
        this.notification.manageFee = Number(((this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 0) *
            Number(this.managePrecent?this.managePrecent:0)) / 100).toFixed("3");
      }
      else
      {
        this.notification.saleFee = 0;
        this.notification.manageFee = 0;
      }

      // 管销费
      this.tubePinFee = Number(((this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 0) *
          Number(0.008))).toFixed("2");

      // 三费 + 业务费 + 管理费
      this.notification.productFee = (Number(this.notification.productFeeOne) + Number(this.notification.saleFee) + Number(this.notification.manageFee)).toFixed("2");
      this.notification.productPrice = (this.notification.productFee / Number(this.notification.conDeail.quantity? this.notification.conDeail.quantity:0)).toFixed("6");
      // 毛利率,不算业务费、管理费
      if (this.notification.conDeail.computAmount == 0)
      {
        this.notification.grossProfitRate = -100
        this.notification.netProfitRate = -100
      }
      else
      {
        // 毛利率
        this.notification.grossProfitRate = ((((this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 0) -
            Number(this.notification.productFeeOne?this.notification.productFeeOne:0)) / (this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 1)) * 100).toFixed("2");
        // 净利率
        this.notification.netProfitRate = ((((this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 0) -
            Number(this.notification.productFee?this.notification.productFee:0) - Number(this.tubePinFee)) / (this.notification.conDeail.computAmount ? Number(this.notification.conDeail.computAmount) : 1)) * 100).toFixed("2");
      }
      // 总部经济费用、单价
      this.notification.economyFee = ((Number(this.notification.productFeeTwo?this.notification.productFeeTwo:0)) * Number(this.materialResult.lnPrecent) / 100).toFixed("2");
      this.notification.economyFeePrice = 0;
      if(this.notification.conDeail.quantity > 0) {
        this.notification.economyFeePrice = (Number(this.notification.economyFee?this.notification.economyFee:0) / Number(this.notification.conDeail.quantity)).toFixed("6");
      }
      this.notification.exEconomyFeePrice = 0;
      this.notification.exEconomyFee = 0;
      if (this.notification.exchangeType && this.notification.exchangeRate && this.notification.economyFee && this.notification.economyFeePrice) {
        this.notification.exEconomyFeePrice = this.notification.economyFeePrice;
        this.notification.exEconomyFee = this.notification.economyFee;
        this.notification.economyFee = (Number(this.notification.economyFee) / Number(this.notification.exchangeRate)).toFixed(6);
        this.notification.economyFeePrice = (Number(this.notification.economyFeePrice) / Number(this.notification.exchangeRate)).toFixed(6);
      }
      // 工厂费用、单价
      this.notification.factoryFee = ((Number(this.notification.productFeeTwo?this.notification.productFeeTwo:0)) * Number(this.materialResult.jxPrecent) / 100).toFixed("2");
      this.notification.factoryFeePrice = 0;
      if(this.notification.conDeail.quantity > 0) {
        this.notification.factoryFeePrice = (Number(this.notification.factoryFee ? this.notification.factoryFee : 0) / Number(this.notification.conDeail.quantity)).toFixed("6");
      }
    },
    coutExcutePrice: function() {
      this.excute = {};
      if (this.notification.conDeail && this.notification.conDeail.recordId) {
        this.excute.totomlMoney = (
          Number(this.setUpPrice.setUpAreaPrice == null?0:this.setUpPrice.setUpAreaPrice) * Number(this.notification.conDeail.orderDeailArea == null ? 0 : this.notification.conDeail.orderDeailArea)
          +
          Number(this.setUpPrice.setUpHolePrice == null ?0:this.setUpPrice.setUpHolePrice) * Number(this.notification.conDeail.countersinkHole == null ? 0 : this.notification.conDeail.countersinkHole)
          +
          Number(this.setUpPrice.setUpPcsPrice == null ?0:this.setUpPrice.setUpPcsPrice) * Number(this.notification.conDeail.quantity == null ? 0 : this.notification.conDeail.quantity)
          + (this.setUpPrice.setUpFixedFee == null ?0:Number(this.setUpPrice.setUpFixedFee))
        ).toFixed("2");
      }
    },
    getMaterialData:function()
    {
      const param = window.location.href.split("?");
      if (param && param.length > 1) {
        const query = param[1];const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
          const pair = vars[i].split("=");
          if (pair[0] === "n_id") {this.query.n_id = pair[1];}
          if (pair[0] === "u_id") {this.query.u_id = pair[1];}
          if (pair[0] === "g_id") {this.query.g_id = pair[1];}
        }
        if (this.query.n_id) {
          if (this.query.u_id) {
            const paramVal = {}; paramVal.recordId = this.query.n_id; paramVal.userId = this.query.u_id; paramVal.groupDbId = this.query.g_id;
            paramVal.length = this.boardLength; paramVal.width = this.boardWidth; paramVal.boardThicknessId = this.boardThicknessId;
            paramVal.copperCladThicknessId = this.copperCladThicknessId;paramVal.materialNo = this.materialNo;const _this = this;
            axios.fetchPost("icloud/contract/getMaterialData", paramVal).then(result => {
              if (result.data) {
                  _this.materialList = result.data.materialList;
                  _this.materialListTwo = result.data.materialListTwo;
                  // 材料平米单价
                  _this.materialPrice = 0;
                  if (_this.materialList && _this.materialList.length > 0){
                    _this.materialPrice = _this.materialList[0].avgPrice;
                  }
                  // 可用料总面积
                  _this.materials = result.data.materials || [];
                  for (let i=0;i<_this.materials.length;i++){
                    if (_this.materials[i].customerId != _this.notification.customerId)
                    {
                      continue;
                    }
                    let canUseArea = _this.materials[i].canUseArea?Number(_this.materials[i].canUseArea):0;
                    _this.materialArea = Number(_this.materialArea) + Number(canUseArea);
                  }
                  _this.manufacturerList = result.data.manufacturerList;
                  _this.orgList = result.data.orgList;

                  _this.showOrgList = [];
                  if(_this.materialList && _this.materialList.length > 0)
                  {
                    for(let mat of _this.materialList)
                    {
                      _this.showOrgList.push(mat);
                    }
                  }
                  if(_this.orgList && _this.orgList.length > 0)
                  {
                    for(let mat of _this.orgList)
                    {
                      let flag = false;
                      if(_this.showOrgList && _this.showOrgList.length > 0)
                      {
                        for(let showOrg of _this.showOrgList)
                        {
                          if(showOrg.recordId == mat.recordId)
                          {
                            flag = true;
                            break;
                          }
                        }
                      }
                      if(!flag)
                      {
                        _this.showOrgList.push(mat);
                      }
                    }
                  }
                _this.countMaterialMoney();
              }
            }).catch(err => {console.log(err);});
          }
        }
      }
    },
  }
};
