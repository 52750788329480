import axios from "@/api";

export default {
  props: ["conDeail", "materialResult", "craftList", "groupCenterLine", "canEditFlag", "setUpArea",
    "acceptFlag", "deailList", "maxBatchArea", "useMatList", "materialList", "materials", "notification",
    "query","craftQuery","materialListTwo","boardLength","boardWidth","boardThicknessId","copperCladThicknessId",
    "materialNo","craft","orderHours","hours"],
  data() {
    return {
      notiNeedDays: 0, estimateRemark: "", productionCycleId: "", estimateDate: "", estimateMethod: "", purchRowDays: "",
      needDays: "", custArea: "", leadTime: 0, urgentApproval: "", useAgeData: "", notiCraftNo: "",
      genCraftNo: "", minDate: "", purchNeeds: 0, dayList: [], showDays: 60, addCapacityList: [],dayAreaEntity: {},
      useArea: 0,maxBatchAreaConfirm:{},groupOrgList:[],groupOrg:{},apacityBorrowList:[],materialCat: {},materialNo: "",
      materialName: "", specification: "",appointMaterialFee:0,materialMsgTwo:null,negotiateAreaPrice:0,processCostPrice:0,
      pingEstimateMethod:"",materialUse:"",approveType:""
    };
  },
  mounted() {
    this.initDayList();
  },
  watch: {
    acceptFlag: function () {
      if (this.canEditFlag) {
        this.initDeliveryDate(this.addCapacityList,1);
      }
    },
    notiCraftNo: function() {
      if (this.canEditFlag) {
        // 选择厂编清除填写厂编
        this.getCraftAvailableQty();
      }
    },
    craftList: function() {
      if (this.canEditFlag) {
        // 默认给合同选择厂编
        this.initCraft();
      }
    },
    groupCenterLine: function() {
      this.loadMaterialList();
    },
    canEditFlag: function() {
      this.loadMaterialList();
      this.initDayList();
    },
    materialList: function (){
      this.loadMaterialList();
    },
    materialResult: function (){
      this.initDayList();
    }
  },
  methods: {
    clearWay: function (flag){
      this.pingEstimateMethod = "";
      if (flag == 1){
        // 如果报备料满足订单，不能按单订料
        this.materialUse = "";
        this.materialCat = {};
        if(this.checkWay())
        {
          this.useAgeData = null;
          alert("报备料已经满足订单，不能选择按单订料");
          return;
        }
      }else if (flag == 2){
          if(this.urgentApproval && this.urgentApproval == '1')
          {
              this.manufacturer = "";
          }
          this.materialUse = "";
      }
      // 客供料
      else if(flag == 3)
      {
        this.materialCat = {};
        this.materialUse = "";
      }
      this.setPingEstimateMethod();
      this.searchMaterialList();
      this.initDeliveryDate();
      if(flag)
      {
        this.sendCountMaterialMoney();
      }
    },
    getMaterialUse(item)
    {
      if (item == "1")
      {
        this.materialCat = {}
      }
      else
      {
/*        if(this.checkWay())
        {
          this.materialUse = {};
          alert("报备料已经满足订单，不能选择生产库存");
          return;
        }*/
      }
      this.materialUse = item;
      this.sendCountMaterialMoney();
    },
    // 产能加急和材料加急
    setPingEstimateMethod:function(){
      this.approve = null;
      let pingEstimateMethod = null;
      if(!(this.urgentApproval == '1'))
      {
        return pingEstimateMethod;
      }
      // 产能加急
      let availableCapacity = 0;
      let orderDeailArea = Number(this.conDeail.orderDeailArea);
      orderDeailArea = (orderDeailArea / this.materialResult.useRatio * 100).toFixed(4);
      if(this.dayList && this.dayList.length > 0)
      {
        for(let day of this.dayList)
        {
          if(day.day > this.conDeail.deliveryDateStr)
          {
            break;
          }
          if(day.canUseCapacity && day.canUseCapacity > 0)
          {
            availableCapacity += Number(day.canUseCapacity)
          }
        }
      }
      if(orderDeailArea >= availableCapacity)
      {
        pingEstimateMethod = "产能需加急:缺少"+(orderDeailArea - availableCapacity).toFixed(4)+"㎡;";
      }
      // 材料加急
      let matEstimateMethod = null;
      let sumUseArea = 0;
      if(!(this.useAgeData && this.useAgeData == 3))
      {
        if(this.useMatList && this.useMatList.length > 0)
        {
          for(let useMat of this.useMatList)
          {
            if(useMat.useArea && useMat.useArea > 0)
            {
              sumUseArea += Number(useMat.useArea);
            }
          }
        }
        if(sumUseArea < orderDeailArea)
        {
          matEstimateMethod = "材料需加急:报备缺少"+(orderDeailArea - sumUseArea).toFixed(4)+"㎡;";
        }
        if(this.materialCat && this.materialCat.recordId)
        {
          let materialArea = (this.materialCat.bordLength * this.materialCat.bordWidth / 1000000).toFixed(4);
          let largeQty = Math.ceil(orderDeailArea / Number(this.materialResult.useRatio) * 100 / materialArea);
          if(largeQty && largeQty > this.materialCat.stocks)
          {
            let spec = this.materialCat.no +"/"+ this.materialCat.name +"/"+ this.materialCat.specification;
            if(matEstimateMethod)
            {
              matEstimateMethod = matEstimateMethod + "选择的材料"+spec+"库存"+this.materialCat.stocks+"低于订单需要的大板数"+largeQty;
            }
            else
            {
              matEstimateMethod = "材料需加急:选择的材料"+spec+"库存"+this.materialCat.stocks+"低于订单需要的大板数"+largeQty;
            }
          }
          if (largeQty)
          {
            let approve = Number(largeQty / this.materialCat.stocks * 100).toFixed(4);
            let date = Number(this.conDeail.deliveryDate - new Date());
            let dateTwo = Math.abs(date) / (1000 * 60 * 60 * 24);
            if (approve < 50 && dateTwo < 3)
            {
              this.approveType = null;
            }
            else
            {
              this.approveType = 1;
            }
          }
        }
      }
      if(pingEstimateMethod && matEstimateMethod)
      {
        pingEstimateMethod += matEstimateMethod;
      }
      else if(matEstimateMethod)
      {
        pingEstimateMethod = matEstimateMethod;
      }
      this.pingEstimateMethod = pingEstimateMethod;
    },
/*    checkUrgent:function(){
      if(!(this.productionCycleId && this.productionCycleId == '3'))
      {
        alert("当交货日期不满足于客户交期时,才能选择特殊加急!");
        this.useAgeData = null;
        return;
      }
      // 获取到客户交期这段时间可用产能
      let availableCapacity = 0;
      if(this.dayList && this.dayList.length > 0)
      {
        for(let day of this.dayList)
        {
          if(day.day > this.conDeail.deliveryDateStr)
          {
            break;
          }
          if(day.canUseCapacity && day.canUseCapacity > 0)
          {
            availableCapacity += Number(day.canUseCapacity)
          }
        }
      }
      if(Number(this.conDeail.orderDeailArea) <= availableCapacity)
      {
        alert("距离客户交期还有可用产能"+availableCapacity+"㎡，不能进行特殊加急!");
        this.useAgeData = null;
        return;
      }
      const groupCenert = {};
      groupCenert.deptId = this.notification.deptId;
      groupCenert.addCapacityList = this.addCapacityList;
      groupCenert.recordId = this.groupCenterLine.recordId;
      axios.fetchPost("icloud/contract/checkCapacity", groupCenert).then(result => {
        if (result.data && result.data.result == "fail") {
          alert(result.data.message);
          this.useAgeData = null;
        }
      }).catch(err => {console.log(err);});
    },*/
    initDayList: function (){
      this.dayList = [];
      if (this.materialResult && this.materialResult.companyId){
        const capacity = {};
        capacity.companyId = this.materialResult.companyId;
        if (this.materialResult.customerName){
          capacity.customerId = this.materialResult.salecustId;
        }else if (this.materialResult.departName){
          capacity.departId = this.materialResult.deptId;
        }
        if (this.conDeail.processValueId){
          capacity.processValueId = this.conDeail.processValueId;
        }
        capacity.contactDeailId = this.conDeail.recordId;
        capacity.day = this.showDays;
        capacity.canEditFlag = this.canEditFlag;
        // 当前时超过设定时的标志
        if(this.orderHours && this.hours && (this.hours > this.orderHours || this.hours == 0))
        {
          capacity.compareFlag = 1;
        }
        const _this = this;
        axios.fetchPost("icloud/contract/getCapacityDetailDateList", capacity).then(result => {
          if (result.data.dayList) {
            _this.dayList = result.data.dayList;
            _this.loadMaterialList();
          }
        }).catch(err => {console.log(err);});
      }
    },
    initDayGroupList: function (){
      this.dayList = [];
      if (this.materialResult && this.materialResult.companyId){
        const capacity = {};
        capacity.companyId = this.materialResult.companyId;
        if (this.materialResult.customerName){
          capacity.customerId = this.materialResult.salecustId;
        }else if (this.materialResult.departName){
          capacity.departId = this.materialResult.deptId;
        }
        if (this.conDeail.processValueId){
          capacity.processValueId = this.conDeail.processValueId;
        }
        capacity.contactDeailId = this.conDeail.recordId;
        capacity.day = this.showDays;
        const _this = this;
        axios.fetchPost("icloud/contract/getCapacityDetailDateList", capacity).then(result => {
          if (result.data.dayList) {
            _this.dayList = result.data.dayList;
            if (this.canEditFlag) {
              _this.initDeliveryDate();
            }
          }
        }).catch(err => {console.log(err);});
      }
    },
    getCraftAvailableQty: function() {
      if (this.notiCraftNo && this.craftList && this.craftList.length > 0) {
        this.genCraftNo = null;
        this.sendMsg();
      } else {
        this.sendMsg();
      }
    },
    judgeOrderType: function() {
      this.estimateRemark = null;
      this.productionCycleId = "";
      let result = false;
      if (this.urgentApproval && this.urgentApproval === "1") {
        // 加急评估
        this.estimateRemark = "加急";
        result = false;
        return result;
      }else if(this.conDeail.oldDeailId) {
        // 补料评估
        this.estimateRemark = "补料";
        result = true;
        return result;
      }else if(!this.conDeail.dictOrderType)
      {
        // 做资料评估
        if (this.conDeail.orderDeailArea && Number(this.conDeail.orderDeailArea) > 0) {
          alert("做资料的订单不能有面积");
        }else {
          this.estimateRemark = "资料";
          result = true;
        }
      }else if (this.conDeail && this.conDeail.dictOrderType == 1) {
        // 样品评估
        let setUpArea = this.setUpArea ? Number(this.setUpArea) : 1;
        if (this.conDeail.orderDeailArea && Number(this.conDeail.orderDeailArea) > setUpArea) {
          alert("非补料、加急的样品订单面积不能超过设置的"+setUpArea+"平米数");
        }else {
          this.estimateRemark = "样品";
          result = true;
        }
      }
      return result;
    },
    initCraft: function() {
      if (this.craftList && this.craftList.length > 0) {
        if (this.conDeail && this.conDeail.oldCraftNo) {
          for (let i = 0; i < this.craftList.length; i++) {
            if (this.craftList[i].no === this.conDeail.oldCraftNo) {this.notiCraftNo = this.craftList[i].recordId;}
          }
        }
        if (!this.notiCraftNo) {
          this.notiCraftNo = this.craftList[0].recordId;
        }
      }
      this.sendMsg();
    },
    valiteCraftNo: function() {
      if (this.genCraftNo) {
        this.notiCraftNo = "";
        this.conDeail.genCraftNo = this.genCraftNo;
        this.conDeail.groupCenterId = this.groupCenterLine.recordId
        // 获取占用料情况以及可用物料
        axios.fetchPost("icloud/contract/valiteCraftNo", this.conDeail).then(data => {
          if (data.data === "fail") {
            alert("该厂编编号已经存在");
            this.genCraftNo = "";
            this.conDeail.genCraftNo = "";
          }
          this.sendMsg();
        }).catch(err => {console.log(err);});
      } else {
        this.sendMsg();
      }
    },
    loadMaterialList: function() {
      if (!this.conDeail){
        return;
      }
      if (this.groupCenterLine && this.groupCenterLine.recordId) {
        this.genCraftNo = "";
        this.notiCraftNo = "";
        if (this.groupCenterLine.genCraftNo) {
          this.genCraftNo = this.groupCenterLine.genCraftNo;
        } else if (this.groupCenterLine.notiCraftNo) {
          this.notiCraftNo = this.groupCenterLine.notiCraftNo;
        }
        if (this.groupCenterLine && this.groupCenterLine.urgentFlag) {
          this.urgentApproval = this.groupCenterLine.urgentFlag;
        }
        if (this.groupCenterLine && this.groupCenterLine.materialUse) {
          this.materialUse = this.groupCenterLine.materialUse;
        }
        if (this.groupCenterLine && this.groupCenterLine.useAgeData) {
          this.useAgeData = this.groupCenterLine.useAgeData;
        }
        if(this.materialListTwo && this.materialListTwo.length > 0)
        {
          for(let mat of this.materialListTwo)
          {
            if(this.groupCenterLine.materialId && mat.recordId == this.groupCenterLine.materialId)
            {
              this.materialCat = mat;
              // 指定平米单价
              let appointAreaPrice = Number((this.materialCat.price / (this.materialCat.bordLength * this.materialCat.bordWidth / 1000000)).toFixed(4));
              let orderArea = Number((this.conDeail.orderDeailArea / this.materialResult.useRatio * 100).toFixed(4));
              this.materialMsgTwo = appointAreaPrice +'*'+ orderArea;
              // 计算指定材料费
              this.appointMaterialFee = Number((appointAreaPrice * orderArea).toFixed(2));
              break;
            }
          }
        }
      }
      if (this.canEditFlag) {
        this.initCraft();
/*        let flag;
        if(this.useAgeData && this.useAgeData != '')
        {
          if(this.useAgeData == 1)
          {
            flag = 1;
          }
          else if(this.useAgeData == 3)
          {
            flag = 3;
          }
        }
        else
        {
          if(this.urgentApproval && this.urgentApproval == 1)
          {
            flag = 2;
          }
        }*/
        this.clearWay();
      }else{
        this.initDeliveryDate(this.addCapacityList);
      }
    },
    sendMsg: function() {
      const val = {};
      val.notiCraftNo = this.notiCraftNo;
      val.genCraftNo = this.genCraftNo;
      val.addCapacityList = this.addCapacityList;
      val.estimateMethod = this.estimateMethod;
      val.estimateDate = this.estimateDate;
      val.notiNeedDays = this.notiNeedDays;
      val.purchRowDays = this.purchRowDays;
      val.estimateRemark = this.estimateRemark;
      val.productionCycleId = this.productionCycleId;
      val.urgentApproval = this.urgentApproval;
      val.useAgeData = this.useAgeData;
      val.materialCat = this.materialCat;
      val.maxBatchAreaConfirm = this.maxBatchAreaConfirm;
      val.materialListTwo = this.materialListTwo;
      val.materialCat = this.materialCat;
      val.materialMsgTwo = this.materialMsgTwo;
      val.appointMaterialFee = this.appointMaterialFee;
      val.negotiateAreaPrice = this.negotiateAreaPrice;
      val.processCostPrice = this.processCostPrice;
      val.pingEstimateMethod = this.pingEstimateMethod;
      val.materialUse = this.materialUse;
      val.approveType = this.approveType;

      val.boardLength = this.boardLength;
      val.boardWidth = this.boardWidth;
      val.boardThicknessId = this.boardThicknessId;
      val.copperCladThicknessId = this.copperCladThicknessId;
      val.materialNo = this.materialNo;

      // 向父组件传输参数
      this.$emit("setMsg", val);
    },
    sendCountMaterialMoney:function(){
      const val = {};
      val.urgentApproval = this.urgentApproval;
      val.materialUse = this.materialUse;
      val.materialCat = this.materialCat;
      this.$emit("handleData",val);
    },
    clearDeliveryDate: function() {
      this.estimateDate = "";
      this.estimateRemark = "";
      this.productionCycleId = "";
      this.estimateMethod = "";
    },
    delBatchCapa: function (item){
      for (let i=0;i<this.addCapacityList.length;i++){
        if (this.addCapacityList[i].proTime == item.proTime){
          this.addCapacityList.splice(i,1);
          break;
        }
      }
      this.initDeliveryDate(this.addCapacityList, 1);
    },
    useCapacity: function (item) {
      this.dayAreaEntity = {};
      this.useArea = 0;
      // 判断最小交期和当前批次交期是否满足
      const s1 = new Date(item.day.replace(/-/g, "/"));
      const s2 = new Date(this.minDate.replace(/-/g, "/"));
      if (s1.valueOf() < s2.valueOf()) {
        alert(item.day + "所选日不满足基本最小交期，请另选日期！");
        return;
      }
      // 是不是选择重复天数的
      for (let row of this.addCapacityList){
        if (item.day && item.day == row.proTime){
          alert(item.day + "已经存在选择日了，不能在同一天安放两个批次！");
          return;
        }
      }
      if (!this.conDeail.oldDeailId && this.conDeail.dictOrderType && !(this.urgentApproval == '1')){
        if (this.conDeail.dictOrderType == 1){
          // 样品
          if (item.canUseSampleOrderCount <= 0){
            alert(item.day + "没有样品款数，不可使用！");
            return;
          }
        }else if (this.conDeail.dictOrderType == 2){
          // 量产
          if (item.canUseOrderCount <= 0){
            alert(item.day + "没有量产款数，不可使用！");
            return;
          }
          if (item.canUseCapacity <= 0){
            alert(item.day + "没有产能，不可使用！");
            return;
          }else {
            // 赋予默认产能
            let orderDeailArea = (Number(this.conDeail.orderDeailArea) > 0) ? Number(this.conDeail.orderDeailArea):0;
            for (let row of this.addCapacityList){
              if (row.deailArea && Number(row.deailArea) > 0){
                orderDeailArea = Number(orderDeailArea) - Number(item.deailArea);
              }
            }
            let useArea = (Number(orderDeailArea) > Number(item.canUseCapacity))?item.canUseCapacity:orderDeailArea;
            if (useArea > 0){
              this.useArea = useArea.toFixed(4);
            }
          }
        }else {
          alert("订单类型有问题，请刷新重试或者联系系统人员！");
          return;
        }
        if (this.conDeail.dictCardType == 1){
          // 新单
          if (item.canUseNewOrderCount <= 0){
            alert(item.day + "没有新单款数，不可使用！");
            return;
          }
        }else if (this.conDeail.dictCardType == 2){
          // 返单
        }else {
          alert("资料类型有问题，请刷新重试或者联系系统人员！");
          return;
        }
      }else {
        alert("补料/加急/做资料不需要进行批次管理，请退出！");
        return;
      }
      // 弹窗使用
      this.dayAreaEntity = item;
      $("#dayAreaEntity").modal();
    },
    runAddCapacity: function (){
      if (this.dayAreaEntity && this.dayAreaEntity.day){
        const capa = {};
        capa.proTime = this.dayAreaEntity.day;
        if (this.conDeail.dictOrderType == 2){
          // 验证面积
          // 日最大产能判断
          let maxBatchArea = (Number(this.maxBatchArea) > 0) ? Number(this.maxBatchArea):0;
          if (!(Number(maxBatchArea) > 0)){
            alert("请设置日最大产能！");
            return;
          }
          // 日最小产能
          let partialArea = (Number(this.materialResult.partialArea) > 0) ? Number(this.materialResult.partialArea):0;
          if (this.useArea && Number(this.useArea) > 0){
            if (Number(this.useArea) > Number(this.dayAreaEntity.canUseCapacity)){
              alert("不能超过可使用面积！");
              return;
            }
            if (Number(this.useArea) < partialArea){
              alert("不能小于最小批次产能！");
              return;
            }
            if (Number(this.useArea) > Number(maxBatchArea)){
              alert("不能大于最大批次产能！");
              return;
            }
          }else {
            alert("没有使用面积，请输入面积！");
            return;
          }
          capa.deailArea = this.useArea;
        }else {
          this.addCapacityList = [];
        }
        this.addCapacityList.push(capa);
      }else {
        alert("数据错误，请退出重试！");
        return;
      }
      this.initDeliveryDate(this.addCapacityList, 1);
      $("#dayAreaEntity").modal('hide');
    },
    initDeliveryDate: function(list, flag) {
      if (!this.conDeail){
        return;
      }
      if (!(this.dayList.length > 0)){
        return;
      }
      this.notiNeedDays = 0;
      this.addCapacityList = [];
      this.clearDeliveryDate();
      let restDays = 0;
      let needDays = 0;
      needDays = Number(this.materialResult.needDays) >=0?Number(this.materialResult.needDays):0;
      let deailId = 1;
      let capacityDay = 0;
      if (this.judgeOrderType()) {
        this.productionCycleId = "1";
        // 样品
        if (this.conDeail.dictOrderType == 1 && !(Number(this.materialResult.sampleOrderCount) > 0)){
          return;
        }
        // 生产周期
        this.estimateMethod = "（生产周期："+ needDays + "天）";
        this.notiNeedDays = needDays;
        // 当前时超过设定时的标志
        if(this.orderHours && this.hours && (this.hours > this.orderHours || this.hours == 0))
        {
          needDays++;
        }
        // 计算最小交货日期
        for (let i=0;i<this.dayList.length;i++) {
          const dayData = this.dayList[i];
          if (dayData.status == 1){
            // 休息日++
            restDays++;
            continue;
          }
          // 减去生产日，再减去休息日
          let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0)
              - Number(needDays) - Number(restDays)).toFixed(2);
          if (needDayNum < 0){
            continue;
          }
          this.minDate = this.dayList[i].day;
          restDays = 0;
          break;
        }
        if (this.conDeail.dictOrderType == 1) {
          // 计算从哪天开始计算
          if (flag == 1){
            // 手动排产
            if (list && list.length > 0){
              for (let i=0;i<list.length;i++){
                const dealDay = list[i];
                if (dealDay.proTime){
                  for (let j=0;j<this.dayList.length;j++){
                    const dayData = this.dayList[j];
                    if (dayData.status == 1) {
                      // 休息日++
                      restDays++;
                      continue;
                    }
                    // 加急只占用小于等于客户交期的产能
                    if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
                    {
                      continue;
                    }
                    // 减去生产日，再减去休息日
                    let needDayNum = ((Number(dayData.needDays) > 0 ? Number(dayData.needDays) : 0)
                        - Number(needDays) - Number(restDays)).toFixed(2);
                    if (needDayNum < 0) {
                      continue;
                    }
                    if (dayData.day == dealDay.proTime){
                      // 新单判断
                      if (this.conDeail.dictCardType == 1){
                        let canUseNewOrderCount = (Number(dayData.canUseNewOrderCount) > 0)?Number(dayData.canUseNewOrderCount):0;
                        if (canUseNewOrderCount <= 0){
                          continue;
                        }
                      }
                      // 样品判断
                      let canUseSampleOrderCount = (Number(dayData.canUseSampleOrderCount) > 0) ? Number(dayData.canUseSampleOrderCount) : 0;
                      if (canUseSampleOrderCount > 0) {
                        capacityDay = (Number(capacityDay) > Number(needDayNum)) ? Number(capacityDay) : Number(needDayNum);
                        // 批次
                        const capacity = this.setCapacity(deailId, dayData);
                        capacity.useFlag = 2;
                        capacity.minDate = this.minDate;
                        this.addCapacityList.push(capacity);
                        break;
                      }
                    }
                  }
                }
              }
            }
          }else {
            // 样品需要计算款数批次
            if (this.verifyOrderCount(needDays)) {
              for (let i = 0; i < this.dayList.length; i++) {
                const dayData = this.dayList[i];
                if (dayData.status == 1) {
                  // 休息日++
                  restDays++;
                  continue;
                }
                // 加急只占用小于等于客户交期的产能
                if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
                {
                  continue;
                }
                // 减去生产日，再减去休息日
                let needDayNum = ((Number(this.dayList[i].needDays) > 0 ? Number(this.dayList[i].needDays) : 0)
                    - Number(needDays) - Number(restDays)).toFixed(2);
                if (needDayNum < 0) {
                  continue;
                }
                if (this.conDeail.dictCardType == 1) {
                  let canUseNewOrderCount = (Number(this.dayList[i].canUseNewOrderCount) > 0) ? Number(this.dayList[i].canUseNewOrderCount) : 0;
                  if (canUseNewOrderCount <= 0) {
                    continue;
                  }
                }
                let canUseSampleOrderCount = (Number(this.dayList[i].canUseSampleOrderCount) > 0) ? Number(this.dayList[i].canUseSampleOrderCount) : 0;
                if (canUseSampleOrderCount > 0) {
                  capacityDay = (Number(capacityDay) > Number(needDayNum)) ? Number(capacityDay) : Number(needDayNum);
                  // 批次
                  const capacity = this.setCapacity(deailId, this.dayList[i]);
                  capacity.useFlag = 2;
                  capacity.minDate = this.minDate;
                  this.addCapacityList.push(capacity);
                  break;
                }
              }
            }
          }
        }else {
          for (let i=0;i<this.dayList.length;i++){
            const dayData = this.dayList[i];
            if (dayData.status == 1){
              // 休息日++
              restDays++;
              continue;
            }
            // 加急只占用小于等于客户交期的产能
            if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
            {
              continue;
            }
            // 减去生产日，再减去休息日
            let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0)
                - Number(needDays) - Number(restDays)).toFixed(2);
            if (needDayNum < 0){
              continue;
            }
            // 加急、补料、资料不需要批次验证，直接走流程
            const capacity = this.setCapacity(deailId, this.dayList[i]);
            capacity.useFlag = 3;
            capacity.minDate = this.minDate;
            this.addCapacityList.push(capacity);
            break;
          }
        }
      }else {
        // 产能判断
        if (this.urgentApproval != 1 && !(Number(this.materialResult.capacity) > 0)){
          return;
        }
        // 日最大产能判断
        let maxBatchArea = (Number(this.maxBatchArea) > 0) ? Number(this.maxBatchArea):0;
        if (!(Number(maxBatchArea) > 0)){
          return;
        }
        // 日最小产能
        let partialArea = (Number(this.materialResult.partialArea) > 0) ? Number(this.materialResult.partialArea):0;
        this.estimateMethod = "（生产周期："+ this.materialResult.needDays + "天）";
        this.estimateRemark = "量产";
        this.productionCycleId = "2";
        // 材料周期
        let purchNeeds = 0;
        // 订单面积
        let orderDeailArea = (Number(this.conDeail.orderDeailArea) > 0) ? Number(this.conDeail.orderDeailArea):0;

        // 非客供料
        if(!(this.useAgeData && this.useAgeData == 3) && !(this.urgentApproval == '1'))
        {
          // 市场报备
          let maxArrivalDate = null;
          if(this.useMatList && this.useMatList.length > 0)
          {
            const arr = this.useMatList.map(({arrivalDate})=> arrivalDate);
            maxArrivalDate = Math.max.apply(null,arr);
          }
          if(maxArrivalDate)
          {
            const nowDate = new Date();
            const days = Math.ceil((maxArrivalDate.valueOf() - nowDate.valueOf()) / 24 / 60 / 60 / 1000);
            if(days > 0)
            {
              purchNeeds = days;
            }
          }
          // 按单订料
          let leadTime = 0;
          if (this.useAgeData && this.useAgeData == 1){
            for (let i=0;i<this.materialList.length;i++){
              if (this.materialList[i].leadTime && this.materialList[i].leadTime > leadTime){
                leadTime = this.materialList[i].leadTime;
              }
            }
          }
          this.leadTime = leadTime;
          // 市场报备和按单订料取最大值
          this.purchRowDays = purchNeeds > leadTime ? purchNeeds : leadTime;
          // 生产周期+订料周期
          needDays = (Number(needDays) + Number(this.purchRowDays)).toFixed(2);
          // 当前时超过设定时的标志
          if(this.orderHours && this.hours && (this.hours > this.orderHours || this.hours == 0))
          {
            needDays++;
          }
          if (purchNeeds > 0 && leadTime > 0){
            if (purchNeeds > leadTime){
              this.estimateMethod += "+(市场报备需要"+ purchNeeds +"天)";
            }else {
              this.estimateMethod += "+(按单订料需要"+ leadTime +"天)";
            }
          }else if (purchNeeds > 0){
            this.estimateMethod += "+(市场报备需要"+ purchNeeds +"天)";
          } else if (leadTime > 0){
            this.estimateMethod += "+(按单订料需要"+ leadTime +"天)";
          }
        }

        // 生产 + 采购
        for (var i=0;i<this.dayList.length;i++) {
          const dayData = this.dayList[i];
          if (dayData.status == 1){
            // 休息日++ 采购日休息日不算休息日
            if (dayData.needDays > this.purchRowDays){
              restDays++;
            }
            continue;
          }
          // 加急只占用小于等于客户交期的产能
          if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
          {
            continue;
          }
          // 日需要天数 - 生产日期 - 休息日（上面代码不算采购日） - 采购日
          let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0) - Number(needDays) - Number(restDays)).toFixed(2);
          if (needDayNum < 0){
            continue;
          }
          restDays = 0;
          this.minDate = this.dayList[i].day;
          break;
        }
        this.notiNeedDays = needDays;
        // 计算从哪天开始计算
        if (flag == 1){
          // 手动排产
          if (list && list.length > 0){
            for (let i=0;i<list.length;i++){
              const dealDay = list[i];
              if (dealDay.proTime && dealDay.deailArea){
                restDays = 0;
                for (let j=0;j<this.dayList.length;j++){
                  const dayData = this.dayList[j];
                  if (dayData.status == 1){
                    // 休息日++ 采购日休息日不算休息日
                    if (dayData.needDays > this.purchRowDays){
                      restDays++;
                    }
                    continue;
                  }
                  // 加急只占用小于等于客户交期的产能
                  if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
                  {
                    continue;
                  }
                  // 日需要天数 - 生产日期 - 休息日（上面代码不算采购日） - 采购日
                  let needDayNum = ((Number(dayData.needDays) > 0? Number(dayData.needDays):0) - Number(needDays) - Number(restDays)).toFixed(2);
                  if (needDayNum < 0){
                    continue;
                  }
                  if (dayData.day == dealDay.proTime){
                    // 新单判断
                    if (this.conDeail.dictCardType == 1){
                      let canUseNewOrderCount = (Number(dayData.canUseNewOrderCount) > 0)?Number(dayData.canUseNewOrderCount):0;
                      if (canUseNewOrderCount <= 0){
                        continue;
                      }
                    }
                    // 量产判断
                    let canUseOrderCount = (Number(dayData.canUseOrderCount) > 0)?Number(dayData.canUseOrderCount):0;
                    if (canUseOrderCount > 0){
                      let canUseCapacity = (Number(dayData.canUseCapacity) > 0)?Number(dayData.canUseCapacity):0;
                      // 日最小产能判断
                      if (dealDay.deailArea >= partialArea){
                        // 控制可使用和最大产能判断
                        if (Number(dealDay.deailArea) <= canUseCapacity && Number(dealDay.deailArea) <= Number(maxBatchArea)){
                          // 设置批次
                          const capacity = this.setCapacity(deailId, dayData);
                          // 批次时间
                          capacityDay = (Number(capacityDay) > Number(needDayNum))?Number(capacityDay):Number(needDayNum);
                          // 批次面积、使用状态、最小交期
                          capacity.deailArea = dealDay.deailArea;
                          capacity.useFlag = 1;
                          capacity.minDate = this.minDate;
                          this.addCapacityList.push(capacity);
                          deailId += 1;
                          // 验证订单面积是不是已经满足
                          orderDeailArea = (Number(orderDeailArea) - Number(dealDay.deailArea)).toFixed(4);
                        }
                      }
                    }
                  }
                  if(dayData.day > dealDay.proTime)
                  {
                    break;
                  }
                }
              }
            }
          }
        } else {
          // 自动排产
          if (this.verifyCapacity(partialArea, needDays, maxBatchArea)){
            restDays = 0;
            for (let i=0;i<this.dayList.length;i++){
              const dayData = this.dayList[i];
              if (dayData.status == 1){
                // 休息日++ 采购日休息日不算休息日
                if (dayData.needDays > this.purchRowDays){
                  restDays++;
                }
                continue;
              }
              // 加急只占用小于等于客户交期的产能
              if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
              {
                continue;
              }
              // 日需要天数 - 生产日期 - 休息日（上面代码不算采购日） - 采购日
              let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0) - Number(needDays) - Number(restDays)).toFixed(2);
              if (needDayNum < 0){
                continue;
              }
              // 新单判断
              if (this.conDeail.dictCardType == 1){
                let canUseNewOrderCount = (Number(this.dayList[i].canUseNewOrderCount) > 0)?Number(this.dayList[i].canUseNewOrderCount):0;
                if (canUseNewOrderCount <= 0){
                  continue;
                }
              }
              // 量产判断
              let canUseOrderCount = (Number(this.dayList[i].canUseOrderCount) > 0)?Number(this.dayList[i].canUseOrderCount):0;
              if (canUseOrderCount > 0){
                let canUseCapacity = (Number(this.dayList[i].canUseCapacity) > 0)?Number(this.dayList[i].canUseCapacity):0;
                // 日最小产能判断
                if (canUseCapacity > partialArea){
                  // 单日使用产能从批次最大产能和单日产能取最小
                  let deailArea = (Number(orderDeailArea) > Number(canUseCapacity))?canUseCapacity:orderDeailArea;
                  // 可用产能和日最大产能控制
                  deailArea = (Number(deailArea) > Number(maxBatchArea))?maxBatchArea:deailArea;
                  // 设置批次
                  const capacity = this.setCapacity(deailId, this.dayList[i]);
                  // 批次时间
                  capacityDay = (Number(capacityDay) > Number(needDayNum))?Number(capacityDay):Number(needDayNum);
                  // 批次面积、使用状态、最小交期
                  capacity.deailArea = (Number(deailArea)).toFixed(4);
                  capacity.useFlag = 1;
                  capacity.minDate = this.minDate;
                  this.addCapacityList.push(capacity);
                  deailId += 1;
                  // 验证订单面积是不是已经满足
                  orderDeailArea = (Number(orderDeailArea) - Number(deailArea)).toFixed(4);
                  if (Number(orderDeailArea) <= 0) {
                    break;
                  }
                }
              }
            }
          }
        }
        // 还有合同明细面积未安排，清掉评估给结果
        if (!(this.useAgeData && this.useAgeData == 3) && !(this.urgentApproval == '1') && Number(orderDeailArea) > 0){
          this.clearDeliveryDate();
          this.estimateMethod = "批次产能不满足";
          this.sendMsg();
          return;
        }
        if (!(this.useAgeData && this.useAgeData == 3) && !(this.urgentApproval == '1') && Number(orderDeailArea) < 0) {
          this.clearDeliveryDate();
          this.estimateMethod = "批次产能超出，请查看";
          this.sendMsg();
          return;
        }
      }
      this.notiNeedDays = needDays;
      if (capacityDay > 0) {
        this.estimateMethod += "+(生产批次"+capacityDay+"天)";
      }
      if (restDays > 0){
        this.estimateMethod += "+(休息日"+restDays+"天)";
      }
      // 当前时超过设定时的标志
      if(this.orderHours && this.hours && (this.hours > this.orderHours || this.hours == 0))
      {
        this.estimateMethod += "+(超过"+(this.orderHours+1)+"点加1天)";
      }
      this.estimateMethod += "向上取整";
      // 从交期列表取最大交期
      if(this.addCapacityList && this.addCapacityList.length > 0)
      {
        if(this.urgentApproval && this.urgentApproval == '1')
        {
          this.estimateDate = this.conDeail.deliveryDateStr;
          this.estimateMethod = "加急按客户交期";
          this.estimateMethod += " 首批交期:"+this.estimateDate + " 最终交期:"+this.estimateDate;
        }else{
          for (let data of this.addCapacityList){
            if (!this.estimateDate){
              this.estimateDate = data.proTime;
            }else {
              const s1 = new Date(this.estimateDate.replace(/-/g, "/"));
              const s2 = new Date(data.proTime.replace(/-/g, "/"));
              if (s1.valueOf() < s2.valueOf()) {
                this.estimateDate = data.proTime;
              }
            }
          }
          if(this.addCapacityList[0].proTime)
          {
            this.estimateMethod += " 首批交期:"+this.addCapacityList[0].proTime;
          }
          if(this.addCapacityList[this.addCapacityList.length - 1].proTime)
          {
            this.estimateMethod += " 最终交期:"+this.addCapacityList[this.addCapacityList.length - 1].proTime;
          }
        }
      }
      else
      {
        if(this.urgentApproval == '1')
        {
          if((!this.pingEstimateMethod || this.pingEstimateMethod == '') && this.starvingNum && this.starvingNum <= 0)
          {
            this.productionCycleId = '4'
            this.estimateRemark = "提示：材料和产能都满足的情况下不能进行加急";
          }
          else
          {
            this.estimateRemark = "加急";
          }
        }
        this.estimateDate = this.conDeail.deliveryDateStr;
      }

      if (this.acceptFlag != 1){
        const s1 = new Date(this.conDeail.deliveryDateStr.replace(/-/g, "/"));
        const s2 = new Date(this.estimateDate.replace(/-/g, "/"));
        if (s1.valueOf() < s2.valueOf()) {
          this.productionCycleId = "3";
          this.estimateRemark = "交期不满足";
          // 获取到客户交期这段时间可用产能
          let availableCapacity = 0;
          if(this.dayList && this.dayList.length > 0)
          {
            for(let day of this.dayList)
            {
              if(day.day > this.conDeail.deliveryDateStr)
              {
                break;
              }
              if(day.canUseCapacity && day.canUseCapacity > 0)
              {
                availableCapacity += Number(day.canUseCapacity)
              }
            }
          }
          if(Number(this.conDeail.orderDeailArea) >= availableCapacity)
          {
            this.estimateRemark += "，是否需申请加急";
          }
        }
      }
      if(this.pingEstimateMethod)
      {
        this.estimateRemark += ";"+this.pingEstimateMethod;
      }
      this.sendMsg();
    },
    addOneDay:function(date){
      // 创建一个新的 Date 对象，避免直接修改原日期对象
      let newDate = new Date(date.getTime());
      // 获取当前日期的天数并加一
      let day = newDate.getDate();
      newDate.setDate(day + 1);
      return this.formatDate(newDate);
    },
    formatDate:function(date){
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const dayA = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${dayA}`;
    },
    verifyCapacity: function (partialArea, needDays, maxBatchArea){
      if (this.showDays > 360){
        return false;
      }
      let orderDeailArea = (Number(this.conDeail.orderDeailArea) > 0) ? Number(this.conDeail.orderDeailArea):0;
      let restDays = 0;
      for (let i=0;i<this.dayList.length;i++){
        const dayData = this.dayList[i];
        if (dayData.status == 1){
          // 休息日++ 采购日休息日不算休息日
          if (dayData.needDays > this.purchRowDays){
            restDays++;
          }
          continue;
        }
        // 加急只占用小于等于客户交期的产能
        if(this.urgentApproval == '1' && dayData.day > this.conDeail.deliveryDateStr)
        {
          continue;
        }
        // 日需要天数 - 生产日期 - 休息日（上面代码不算采购日） - 采购日
        let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0) - Number(needDays) - Number(restDays)).toFixed(2);
        if (needDayNum < 0){
          continue;
        }
        // 新单
        if (this.conDeail.dictCardType == 1){
          let canUseNewOrderCount = (Number(this.dayList[i].canUseNewOrderCount) > 0)?Number(this.dayList[i].canUseNewOrderCount):0;
          if (canUseNewOrderCount <= 0){
            continue;
          }
        }
        // 量产
        let canUseOrderCount = (Number(this.dayList[i].canUseOrderCount) > 0)?Number(this.dayList[i].canUseOrderCount):0;
        if (canUseOrderCount > 0){
          // 面积
          let deailArea = (Number(this.dayList[i].canUseCapacity) > 0)?Number(this.dayList[i].canUseCapacity):0;
          if (deailArea > partialArea){
            deailArea = (Number(deailArea) > Number(maxBatchArea))?maxBatchArea:deailArea;
            orderDeailArea = (Number(orderDeailArea) - Number(deailArea)).toFixed(4);
          }
        }
        if (orderDeailArea <= 0){
          return true;
        }
      }
      if (!(this.urgentApproval == '1') && Number(orderDeailArea) > 0){
        this.showDays = this.showDays + 5;
        this.initDayGroupList();
      }
      return true;
    },
    verifyOrderCount: function (needDays){
      if (this.showDays > 360){
        return false;
      }
      let restDays = 0;
      for (let i=0;i<this.dayList.length;i++){
        const dayData = this.dayList[i];
        if (dayData.status == 1){
          // 休息日++
          restDays++;
          continue;
        }
        // 减去生产日，再减去休息日
        let needDayNum = ((Number(this.dayList[i].needDays) > 0? Number(this.dayList[i].needDays):0)
            - Number(needDays) - Number(restDays)).toFixed(2);
        if (needDayNum < 0){
          continue;
        }
        // 新单款数
        if (this.conDeail.dictCardType == 1){
          let canUseNewOrderCount = (Number(this.dayList[i].canUseNewOrderCount) > 0)?Number(this.dayList[i].canUseNewOrderCount):0;
          if (canUseNewOrderCount <= 0){
            continue;
          }
        }
        // 样品款数
        let canUseSampleOrderCount = (Number(this.dayList[i].canUseSampleOrderCount) > 0)?Number(this.dayList[i].canUseSampleOrderCount):0;
        if (canUseSampleOrderCount > 0){
          return true;
        }
      }
      this.showDays = this.showDays + 5;
      this.initDayGroupList();
      return false;
    },
    setCapacity: function (deailId, day){
      const capacity = {};
      capacity.deailId = deailId;
      capacity.proTime = day.day;
      if (this.materialResult.customerName){
        capacity.customerId = this.materialResult.salecustId;
      }else if (this.materialResult.departName){
        capacity.departId = this.materialResult.deptId;
      }
      capacity.processValueId = this.conDeail.processValueId;
      capacity.versionDate = this.materialResult.capacityVersionDate;
      capacity.companyId = this.materialResult.companyId;
      capacity.contactDeailId = this.conDeail.recordId;
      capacity.dayAreas = day.allCapacity;
      capacity.orderNum = day.allOrderCount;
      capacity.sampleOrderNum = day.allSampleOrderCount;
      capacity.newOrderNum = day.allNewOrderCount;
      capacity.cardType = this.conDeail.dictCardType;
      capacity.needDays = day.needDays;
      return capacity;
    },
    checkWay:function()
    {
      let flag = false;
      let orderDeailArea = Number(this.conDeail.orderDeailArea);
      if(this.materialResult && this.materialResult.useRatio)
      {
        orderDeailArea = (orderDeailArea / Number(this.materialResult.useRatio) * 100).toFixed(4);
      }
      let useMatList = this.useMatList;
      if(!useMatList || useMatList.length == 0)
      {
        let starvingNum = orderDeailArea;
        for(let material of this.materials)
        {
          if (material.customerId && material.customerId != this.notification.customerId)
          {
            continue;
          }
          if(!material.canUseArea || material.canUseArea == 0)
          {
            continue;
          }
          let canUseArea = Number(material.canUseArea);
          let computArea = 0;
          if(starvingNum > canUseArea)
          {
            starvingNum = starvingNum - canUseArea;
            computArea = canUseArea;
          }
          else
          {
            computArea = starvingNum;
            starvingNum = 0;
          }
          if(computArea > 0)
          {
            let useMat = {};
            useMat.specification = material.specification;
            useMat.useArea = computArea;
            useMat.matPreparationId = material.matPreparationId;
            useMat.deptId = this.materialResult.deptId
            useMat.arrivalDate = material.arrivalDate;
            useMatList.push(useMat)
          }
          if(starvingNum == 0)
          {
            break;
          }
        }
      }
      let sumArea = 0;
      for(let useMat of useMatList)
      {
        sumArea += useMat.useArea ? Number(useMat.useArea) : 0;
      }
      if(sumArea >= Number(orderDeailArea))
      {
        flag = true;
      }
      return flag;
    },
    loadUseData: function (item){
      this.dayAreaEntity = item;
      $("#loadUseData").modal();
    },
    apacityManagementOpen:function(row)
    {
      if(!row)
      {
        return;
      }
      this.maxBatchAreaConfirm = {};
      this.maxBatchAreaConfirm.useDate = row.day;
      if(this.materialResult && this.materialResult.deptId)
      {
        this.maxBatchAreaConfirm.deptAfterId = this.materialResult.deptId;
      }
      if(this.conDeail && this.conDeail.processValueId)
      {
        this.maxBatchAreaConfirm.productType = this.conDeail.processValueId;
      }
      const _this = this;

      const capacity = {};
      capacity.companyId = this.materialResult.companyId;
      if (this.conDeail.processValueId)
      {
        capacity.processValueId = this.conDeail.processValueId;
      }
      capacity.contactDeailId = this.conDeail.recordId;
      capacity.proTime = row.day;
      axios.fetchPost("icloud/contract/getCapacityDetailDateDay",capacity).then(result => {
        _this.groupOrgList = result.data;
        if(_this.groupOrgList && _this.groupOrgList.length > 0)
        {
          for(let groupOrg of _this.groupOrgList)
          {
            if(!groupOrg.capacityUse)
            {
              continue;
            }
            let use = groupOrg.capacityUse;
            use.canUseCapacity = (use.capacity ? Number(use.capacity) : 0) - (use.useCapacity ? Number(use.useCapacity) : 0);
            use.canUseAmount = (use.amount ? Number(use.amount) : 0) - (use.useAmount ? Number(use.useAmount) : 0);
            use.canUseSampleAmount = (use.sampleAmount ? Number(use.sampleAmount) : 0) - (use.useSampleAmount ? Number(use.useSampleAmount) : 0);
            use.canUseOrderAmount = (use.orderAmount ? Number(use.orderAmount) : 0) - (use.useOrderAmount ? Number(use.useOrderAmount) : 0);
          }
        }
        $("#apacityManagementStatic").modal();
      }).catch(err => {console.log(err);});
    },
    useCapacityDeail:function(row)
    {
      this.maxBatchAreaConfirm.deptId = row.recordId;
      this.groupOrg = row;
      $("#apacityManagementUseStatic").modal();
    },
    checkUseDept:function(num)
    {
      if(!num || isNaN(num))
      {
        return;
      }
      if(!this.groupOrg || !this.groupOrg.capacityUse)
      {
        return;
      }
      let useNum = 0;
      let useTwoNum = 0;
      let message = null;
      switch (num) {
        case 1:
          useNum = this.groupOrg.capacityUse.canUseSampleAmount ? Number(this.groupOrg.capacityUse.canUseSampleAmount) : 0;
          useTwoNum = this.maxBatchAreaConfirm.sampleNum ? Number(this.maxBatchAreaConfirm.sampleNum) : 0;
          message = "样品款数不能超过借用部门可用样品款数";
          break;
        case 2:
          useNum = this.groupOrg.capacityUse.amount ? Number(this.groupOrg.capacityUse.amount) : 0;
          useTwoNum = this.maxBatchAreaConfirm.massProductionNum ? Number(this.maxBatchAreaConfirm.massProductionNum) : 0;
          message = "量产款数不能超过借用部门可用量产款数";
          break;
        case 3:
          useNum = this.groupOrg.capacityUse.orderAmount ? Number(this.groupOrg.capacityUse.orderAmount) : 0;
          useTwoNum = this.maxBatchAreaConfirm.newOrderNum ? Number(this.maxBatchAreaConfirm.newOrderNum) : 0;
          message = "新单款数不能超过借用部门可用新单款数";
          break;
        case 4:
          useNum = this.groupOrg.capacityUse.capacity ? Number(this.groupOrg.capacityUse.capacity) : 0;
          useTwoNum = this.maxBatchAreaConfirm.area ? Number(this.maxBatchAreaConfirm.area) : 0;
          message = "产能不能超过借用部门可用产能";
          break;
      }
      if(useNum< useTwoNum)
      {
        switch (num) {
          case 1:
            this.maxBatchAreaConfirm.sampleNum = null;
            break;
          case 2:
            this.maxBatchAreaConfirm.massProductionNum = null;
            break;
          case 3:
            this.maxBatchAreaConfirm.newOrderNum = null;
            break;
          case 4:
            this.maxBatchAreaConfirm.area = null;
            break;
        }
        alert(message)
        return;
      }
    },
    saveRecord:function(){
      if(!this.maxBatchAreaConfirm.deptId)
      {
        alert("借用部门不能为空!");
        return;
      }
      if(!this.maxBatchAreaConfirm.deptAfterId)
      {
        alert("使用部门不能为空!");
        return;
      }
      if(this.maxBatchAreaConfirm.deptId == this.maxBatchAreaConfirm.deptAfterId)
      {
        alert("借用部门与使用部门不能一致!");
        return;
      }
      if(!this.maxBatchAreaConfirm.productType)
      {
        alert("生产类型不能为空!");
        return;
      }
      if(!this.maxBatchAreaConfirm.sampleNum && !this.maxBatchAreaConfirm.massProductionNum && !this.maxBatchAreaConfirm.newOrderNum
          && !this.maxBatchAreaConfirm.area)
      {
        alert("您没有借用款数和产能不需要保存!");
        return;
      }
      this.maxBatchAreaConfirm.type = 3;
      this.maxBatchAreaConfirm.userId = this.query.u_id;
      this.maxBatchAreaConfirm.contractDetailId = this.query.n_id;
      axios.fetchPost("icloud/contract/saveHandleRecord",this.maxBatchAreaConfirm).then(result => {
        if(result.data.result == "success")
        {
          this.maxBatchAreaConfirm.recordId = result.data.confirmId;
          this.initDayList();
          $('#apacityManagementStatic').modal('hide');
        }
        alert(result.data.message)
      }).catch(err => {console.log(err);});
    },
   apacityBorrowOpen:function(){
     axios.fetchPost("icloud/contract/apacityBorrowOpen").then(result => {
         this.apacityBorrowList = result.data;
         $('#showBorrowData').modal();
     }).catch(err => {console.log(err);});
    },
    getMaterialList: function(index) {
      this.useFlag = "";
      if (index || index === 0) {
        this.useFlag = index;
      }
      $("#loadMaterialList").modal();
    },
    searchMaterialList: function() {
      const material = {};
      material.erpCompanyId = this.conDeail.companyId;
      material.no = this.materialNo;
      material.name = this.materialName;
      material.saleId = this.materialResult.saleCompanyId;
      if(this.useAgeData && this.useAgeData == '3')
      {
        material.useAgeData = this.useAgeData;
      }
      else
      {
        material.no = this.materialNos ? this.materialNos : '';
        material.bordLength = this.bordLength ? this.bordLength : '';
        material.boardWidth = this.boardWidth ? this.boardWidth : '';
        material.boardLevelId = this.craftQuery.boardLevel;
        material.materialTypeId = this.craftQuery.materialType;
        material.boardThicknessId = this.boardThicknessId ? this.boardThicknessId : this.craftQuery.boardThickness;
        material.copperCladThicknessId = this.copperCladThicknessId ? this.copperCladThicknessId : this.craftQuery.copperCladThickness;
        material.daoreId = this.craftQuery.daore;
        material.naiyaId = this.craftQuery.naiya;
        material.specification = this.specification;
      }
      axios.fetchPost("icloud/contract/searchMaterialList", material).then(data => {
        this.materialListTwo = data.data;
        this.sendMsg();
      }).catch(err => {console.log(err);});
    },
    sortTbale:function (num){
      if(!num) {return;}
      let flag = null;
      switch (num)
      {
        case 1:
          this.orderBy.noFlag = !this.orderBy.noFlag;
          flag = this.orderBy.noFlag;
          break;
        case 2:
          this.orderBy.stockFlag = !this.orderBy.stockFlag;
          flag = this.orderBy.stockFlag;
          break;
      }
      this.sortList(this.materialListTwo,flag,num);
    },
    sortList:function(list,flag,num){
      if(!list || !num) {return;}
      switch (num)
      {
        case 1:
          if(flag) {list.sort(function(a,b){return a.no.localeCompare(b.no)});}
          else {list.sort(function(a,b){return b.no.localeCompare(a.no)});}
          break;
        case 2:
          if(flag) {list.sort(function(a,b){
            return Number(a.stocks ? a.stocks : 0) + Number(a.inPurchStocks ? a.inPurchStocks : 0)
                - (Number(b.stocks ? b.stocks : 0) + Number(b.inPurchStocks ? b.inPurchStocks : 0))});}
          else
          {list.sort(function(a,b){return Number(b.stocks ? b.stocks : 0) + Number(b.inPurchStocks ? b.inPurchStocks : 0) -
              (Number(a.stocks ? a.stocks : 0) + Number(a.inPurchStocks ? a.inPurchStocks : 0))});}
          break;
      }
    },
    getMaterialSelect: function (item){
      this.getMaterialStocks(item);
    },
    getMaterialStocks: function(item) {
      // 使用主料验证
      if (item && item.recordId){
        this.materialCat = item;
        // 指定平米单价
        let appointAreaPrice = Number((this.materialCat.price / (this.materialCat.bordLength * this.materialCat.bordWidth / 1000000)).toFixed(4));
        let orderArea = Number((this.conDeail.orderDeailArea / this.materialResult.useRatio * 100).toFixed(4));
        this.materialMsgTwo = appointAreaPrice +'*'+ orderArea;
        // 计算指定材料费
        this.appointMaterialFee = Number((appointAreaPrice * orderArea).toFixed(2));
      }
      this.sendCountMaterialMoney();
      this.setPingEstimateMethod();
      this.initDeliveryDate();
      this.sendMsg();
    },
    handleMaterialData:function(){
      $("#materialQueryValue").modal('hide');
      this.sendMsg();
      // 调用父组件方法
      this.$emit("getMaterialData", null);
    },
    materialQueryValueShwo:function (){
      this.boardThicknessId = this.boardThicknessId ? this.boardThicknessId : this.conDeail.boardThickness;
      this.copperCladThicknessId = this.copperCladThicknessId ? this.copperCladThicknessId : this.conDeail.copperCladThickness;
      $("#materialQueryValue").modal('show');
    }
  }
};
